import { TakerMarket } from '@/data/useTakerMarkets';

export interface SetOfferFormInputs {
  rate: string | null;
  rateType: 'apr' | 'disc';
  expireOn: string | null;
}

export interface OfferLimits {
  disc: { low: number; high: number };
  apr: { low: number; high: number };
}

interface ValidateRateInput {
  rate: SetOfferFormInputs['rate'];
  rateType: SetOfferFormInputs['rateType'];
  offerLimits: OfferLimits;
}

const rateWithinLimits = ({ rate, low, high }: { rate: string | null; low: number; high: number }) =>
  !!rate && !isNaN(parseFloat(rate)) && parseFloat(rate) >= low && parseFloat(rate) <= high;

export const validateRate = ({ rate, rateType, offerLimits }: ValidateRateInput): boolean =>
  rateWithinLimits({
    rate,
    ...(rateType === 'disc'
      ? {
          low: offerLimits.disc.low,
          high: offerLimits.disc.high,
        }
      : {
          low: offerLimits.apr.low,
          high: offerLimits.apr.high,
        }),
  });

export const getRateFromTakerMarket = (takerMarket: TakerMarket) => {
  return takerMarket.offerConfig.id && takerMarket.offerConfig.isDiscountBidding
    ? takerMarket.offerConfig.maxDiscount ?? 0
    : takerMarket.offerConfig.id && takerMarket.offerConfig.maxApr
    ? takerMarket.offerConfig.maxApr
    : 0;
};

export const getRateTypeFromTakerMarket = (takerMarket: TakerMarket) => {
  return !takerMarket.offerConfig.id || takerMarket.offerConfig.isDiscountBidding === null
    ? 'disc' // default to discount for net new offers
    : takerMarket.offerConfig.isDiscountBidding
    ? 'disc'
    : 'apr';
};
