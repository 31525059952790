type AsyncFunction<T, R> = (item: T) => Promise<R>;

interface BatchResult<T, R> {
  errors: { item: T; error: Error }[];
  successes: { item: T; result: Awaited<R> }[];
}

const batchPromiseAllSettled = async <T, R>({
  fn,
  items,
  size,
}: {
  fn: AsyncFunction<T, R>;
  items: T[];
  size: number;
}): Promise<BatchResult<T, R>> => {
  const errors: { item: T; error: Error }[] = [];
  const successes: { item: T; result: Awaited<R> }[] = [];

  for (let i = 0; i < items.length; i += size) {
    const batch = items.slice(i, i + size);

    const promises = batch.map(async (item) => {
      try {
        const result = await fn(item);
        successes.push({ item, result });
      } catch (error) {
        errors.push({ item, error: error as Error });
      }
    });

    await Promise.allSettled(promises);
  }

  return { errors, successes };
};

export default batchPromiseAllSettled;
