import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  cn,
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@c2fo/liquidity';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import tabLinks from '@/features/takerMarketTabs/tabLinks';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useSelectedCurrency from '@/utils/useSelectedCurrency';

export interface MarketTypeSelectorItem {
  key: TakerMarketGroupType;
  active: boolean;
  amount: number;
  count: number;
  label: string;
  link: string;
  newDivisionCount: number;
  takerMarkets: TakerMarket[];
}

const MarketTypeSelector = ({ items }: { items: MarketTypeSelectorItem[] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { track } = useReporting();
  const { asCurrency } = useLocaleFormat();
  const selectedCurrency = useSelectedCurrency();
  const { listenToMarketStats } = useServerSideEventListeners();
  const noNewDivisions = items.every((item) => item.newDivisionCount === 0);

  const trackMarketTypeTabChange = (type: string) => track('market-type-tabs:changed', { type });

  return (
    <>
      {/* mobile dropdown */}
      <div className="mb-2 lg:hidden">
        <Select
          onValueChange={(value) => {
            trackMarketTypeTabChange(items.find((item) => item.link === value)?.key ?? '');
            navigate(value, { preventScrollReset: true });
          }}
          value={items.find((item) => item.active)?.link ?? tabLinks.NAME_YOUR_RATE}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {items.map((item, i) => {
              const showPreferredMarketing = item.key === 'PREFERRED' && !item.count;

              return (
                <Fragment key={item.key}>
                  <SelectItem value={item.link}>
                    <div className="truncate">{item.label}</div>
                    {item.newDivisionCount > 0 && (
                      <Chip
                        size="xs"
                        type="info"
                        variant="filled"
                        isRound
                        label={`${item.newDivisionCount} ${
                          item.newDivisionCount === 1 ? t('core.newDivision') : t('core.newDivisions')
                        }`}
                      />
                    )}
                    {showPreferredMarketing ? (
                      <Chip className="mt-1" size="xs" type="info" variant="filled" isRound label={t('core.new')} />
                    ) : (
                      <div className="truncate text-lg font-medium">{asCurrency(item.amount, selectedCurrency)}</div>
                    )}
                  </SelectItem>
                  {i < items.length - 1 && <SelectSeparator />}
                </Fragment>
              );
            })}
          </SelectContent>
        </Select>
      </div>
      {/* desktop tabs */}
      <IntercomDataTarget className="hidden items-end lg:flex" target="marketsTabSection">
        {items.map((item) => {
          const showPreferredMarketing = item.key === 'PREFERRED' && !item.count;
          const listeningForMarketStat = listenToMarketStats(
            item.takerMarkets.map(({ marketUuid, takerDivisionId }) => ({
              marketUuid,
              takerId: takerDivisionId,
            }))
          );

          return (
            <Link
              key={item.key}
              aria-label={item.label}
              className={cn(
                'flex h-40 w-64 shrink-0 flex-col items-end rounded-t-md border-l-2 border-r-2 border-gray-50 bg-white capitalize first-of-type:border-l-transparent last-of-type:border-r-transparent hover:bg-lightBlue-100 focus:outline-secondary-700',
                {
                  'h-44 rounded-t-md border-lightBlue-500 bg-lightBlue-500 hover:bg-lightBlue-500': item.active,
                  'h-28': noNewDivisions,
                  'h-32': noNewDivisions && item.active,
                }
              )}
              onClick={() => trackMarketTypeTabChange(item.key)}
              preventScrollReset
              to={item.link}
            >
              <IntercomDataTarget
                className={cn('h-full w-full flex-col justify-between p-4', {
                  'mt-2 bg-white': item.active,
                  flex: !showPreferredMarketing,
                })}
                target={`${item.key}Tab`}
              >
                <div className="space-y-1">
                  <div className={cn('overflow-hidden text-ellipsis', { 'font-medium': item.active })}>
                    {item.label}
                  </div>
                  {item.newDivisionCount > 0 && (
                    <div className="inline-flex">
                      <Chip
                        size="xs"
                        type="info"
                        variant="filled"
                        isRound
                        label={`${item.newDivisionCount} ${
                          item.newDivisionCount === 1 ? t('core.newDivision') : t('core.newDivisions')
                        }`}
                      />
                    </div>
                  )}
                </div>
                {showPreferredMarketing ? (
                  <div className="inline-flex">
                    <Chip size="xs" type="info" variant="filled" isRound label={t('core.new')} />
                  </div>
                ) : (
                  <div>
                    <div className="text-sm text-text-secondary">{t('core.availableAmount')}</div>
                    <div className={cn('text-xl font-medium', { 'blur-sm': listeningForMarketStat })}>
                      {asCurrency(item.amount, selectedCurrency)}
                    </div>
                  </div>
                )}
              </IntercomDataTarget>
              {item.active && <div className="-mb-2 h-2 w-full bg-white" />}
            </Link>
          );
        })}
      </IntercomDataTarget>
    </>
  );
};

export default MarketTypeSelector;
