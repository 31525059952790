import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import { TakerMarket } from '@/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import { TakerMarketGroupType } from './useTakerMarketsGroups';

export interface ExcludeFutureInvoiceVariables {
  makerOrganizationUuid: string;
  offerUuid: string;
  supplierDivisionUuid: string;
  marketUuid: string;
  takerMarketGroupType: TakerMarketGroupType;
}

export const useExcludeFutureInvoices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['excludeFutureInvoices'],
    mutationFn: ({ makerOrganizationUuid, offerUuid, takerMarketGroupType }: ExcludeFutureInvoiceVariables) => {
      if (takerMarketGroupType === 'BENCHMARK') {
        return apiClient
          .patch('api/supplier-experience/offers/benchmark/toggle-exclude-new-invoices', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              offers: [
                {
                  uuid: offerUuid,
                  makerOrganizationUuid,
                },
              ],
            } satisfies paths['/offers/benchmark/toggle-exclude-new-invoices']['patch']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/benchmark/toggle-exclude-new-invoices']['patch']['responses']['204']['content']>();
      }

      if (takerMarketGroupType === 'FIXED') {
        return apiClient
          .patch('api/supplier-experience/offers/static/toggle-exclude-new-invoices', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              offers: [
                {
                  uuid: offerUuid,
                  makerOrganizationUuid,
                },
              ],
            } satisfies paths['/offers/static/toggle-exclude-new-invoices']['patch']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/static/toggle-exclude-new-invoices']['patch']['responses']['204']['content']>();
      }

      return apiClient
        .patch('api/supplier-experience/offers/price-discovery/toggle-exclude-new-invoices', {
          json: {
            groupingKey: 'SUPPLIER_MARKET',
            offers: [
              {
                uuid: offerUuid,
                makerOrganizationUuid,
              },
            ],
          } satisfies paths['/offers/price-discovery/toggle-exclude-new-invoices']['patch']['requestBody']['content']['application/json'],
        })
        .json<paths['/offers/price-discovery/toggle-exclude-new-invoices']['patch']['responses']['204']['content']>();
    },
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
        (prevTakerMarkets ?? []).map((tm) => ({
          ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
            ? {
                ...tm,
                offerConfig: {
                  ...tm.offerConfig,
                  exclusionSettings: {
                    ...tm.offerConfig.exclusionSettings,
                    excludeNewInvoices: !tm.offerConfig.exclusionSettings.excludeNewInvoices,
                  },
                },
              }
            : tm),
        }))
      );
    },
  });
};
