import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useMarketLayoutOutletContext } from '@/components/MarketLayout';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import useClearHistory, { useClearHistoryStats, usePendingClears } from '@/data/useClearHistory';
import useTakerMarketStats from '@/data/useTakerMarketStats';
import CreditNoteModal from '@/features/history/CreditNoteModal';
import History from '@/features/history/History';
import RangeHeader from '@/features/history/RangeHeader';
import { dateRanges } from '@/features/history/utils';
import { useReporting } from '@/reporting';
import { useToken } from '@/utils/token';
import useReducerWithHistory from '@/utils/useReducerWithHistory';

type Direction = 'asc' | 'desc';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

interface State {
  sort: string;
  sortDirection: Direction;
  pendingSort: string;
  pendingSortDirection: Direction;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
  creditNoteDownloadUrl: string | null;
}

type Action =
  | {
      type: 'SET_CREDIT_NOTE_DOWNLOAD_URL';
      creditNoteDownloadUrl: State['creditNoteDownloadUrl'];
    }
  | { type: 'SET_SORT'; sort: { sort: string; sortDirection: Direction } }
  | {
      type: 'SET_PENDING_SORT';
      sort: { sort: string; sortDirection: Direction };
    }
  | { type: 'SET_RANGE'; range: { startDate: string; endDate: string } }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_LIMIT'; limit: number };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_CREDIT_NOTE_DOWNLOAD_URL':
      return {
        ...state,
        creditNoteDownloadUrl: action.creditNoteDownloadUrl,
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: action.sort.sort,
        sortDirection: action.sort.sortDirection,
      };
    case 'SET_PENDING_SORT':
      return {
        ...state,
        pendingSort: action.sort.sort,
        pendingSortDirection: action.sort.sortDirection,
      };
    case 'SET_RANGE':
      return {
        ...state,
        startDate: action.range.startDate,
        endDate: action.range.endDate,
      };
    case 'SET_PAGE':
      return { ...state, page: action.page };
    default:
      return state;
  }
}

const defaultRange = dateRanges.last30Days;

function MarketHistory() {
  const { track } = useReporting();
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useToken();
  const {
    urlParams: { marketId, takerId },
  } = useMarketLayoutOutletContext();

  const [
    {
      prev,
      curr: {
        startDate,
        endDate,
        sort,
        page,
        sortDirection,
        limit,
        pendingSort,
        pendingSortDirection,
        creditNoteDownloadUrl,
      },
    },
    dispatch,
  ] = useReducerWithHistory(reducer, {
    startDate: searchParams.get('startDate') ?? defaultRange.startDate,
    endDate: searchParams.get('endDate') ?? defaultRange.endDate,
    sort: 'payDate',
    sortDirection: 'desc',
    pendingSort: 'created',
    pendingSortDirection: 'desc',
    page: 1,
    limit: 100,
    creditNoteDownloadUrl: null,
  });

  const urlCreditNoteId = searchParams.get('creditNoteId');
  const urlStartDate = searchParams.get('startDate');
  const urlEndDate = searchParams.get('endDate');

  useEffect(() => {
    // If URL parameters don't match the current state, dispatch to update the application state.
    if ((urlStartDate && urlStartDate !== startDate) || (urlEndDate && urlEndDate !== endDate)) {
      dispatch({
        type: 'SET_RANGE',
        range: {
          startDate: urlStartDate || startDate,
          endDate: urlEndDate || endDate,
        },
      });
    }
  }, [urlStartDate, urlEndDate, startDate, endDate, dispatch]);

  useEffect(() => {
    // If the current state is not the same as the URL, update the URL.
    const urlStartDate = searchParams.get('startDate');
    const urlEndDate = searchParams.get('endDate');

    if (startDate !== urlStartDate || endDate !== urlEndDate) {
      setSearchParams({ startDate, endDate, ...(urlCreditNoteId && { creditNoteId: urlCreditNoteId }) });
    }

    // If the current state is not the same as the previous state, dispatch an update.
    if (startDate !== prev?.startDate || endDate !== prev?.endDate) {
      dispatch({
        type: 'SET_RANGE',
        range: { startDate, endDate },
      });
    }
  }, [startDate, endDate, prev, setSearchParams, dispatch, searchParams, urlCreditNoteId]);

  useEffect(() => {
    if (urlCreditNoteId) {
      dispatch({
        type: 'SET_CREDIT_NOTE_DOWNLOAD_URL',
        creditNoteDownloadUrl: `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/retrieve-award-file/${urlCreditNoteId}?token=${token}`,
      });
    }
  }, [dispatch, marketId, searchParams, takerId, token, urlCreditNoteId]);

  useEffectOnce(() => {
    const download = searchParams.get('download');
    const payDate = searchParams.get('payDate');

    if (download && payDate) {
      // immediately initiate a download
      window.location.href = `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/archive/${payDate}/csv?token=${token}`;
    }
  });

  const awardFileDownloadLink = useMemo(
    () =>
      `${prefixUrl}/api/c2fo/taker/${takerId}/market/${marketId}/archive/csv?startDate=${startDate}&endDate=${endDate}&token=${token}`,
    [token, takerId, marketId, startDate, endDate]
  );

  const { data: market } = useTakerMarketStats({ marketId, takerId });

  const { data: paginatedClears = [], isFetching: fetchingPaginatedClears } = useClearHistory(takerId, marketId, {
    startDate,
    endDate,
    order: sort,
    orderDirection: sortDirection,
    page: 1,
    limit,
  });

  const hasClearHistory = !!paginatedClears?.length;

  const { data: chartData = [] } = useClearHistory(
    takerId,
    marketId,
    {
      startDate,
      endDate,
      order: 'payDate',
      orderDirection: 'asc',
    },
    { enabled: hasClearHistory }
  );

  const { data: stats } = useClearHistoryStats(
    takerId,
    marketId,
    {
      startDate,
      endDate,
    },
    { enabled: hasClearHistory }
  );

  const { data: pendingData, isFetching: fetchingPendingData } = usePendingClears(takerId, marketId, {
    order: pendingSort,
    orderDirection: pendingSortDirection,
  });

  const closeCreditNoteModal = () => {
    // clear download url and close modal
    dispatch({
      type: 'SET_CREDIT_NOTE_DOWNLOAD_URL',
      creditNoteDownloadUrl: null,
    });

    // remove credit note id from URL
    const searchParamsObj = Object.fromEntries(searchParams);
    delete searchParamsObj.creditNoteId;
    setSearchParams(searchParamsObj);
  };

  const handleCreditNoteDownload = () => {
    window.location.href = creditNoteDownloadUrl!;
    track('history-credit-note-request::submitted');
    closeCreditNoteModal();
  };

  return (
    <>
      <div className="rounded-md bg-white shadow-md">
        {market && (
          <>
            <RangeHeader
              isDownloadDisabled={!hasClearHistory}
              startDate={startDate}
              endDate={endDate}
              awardFileDownloadLink={awardFileDownloadLink}
            />
            <History
              currency={market.currency}
              startDate={startDate}
              endDate={endDate}
              onDateRangeChange={(startDate, endDate) => {
                dispatch({ type: 'SET_RANGE', range: { startDate, endDate } });
              }}
              sort={sort}
              onSortChange={(sortKey) => {
                const [, desc, sort] = sortKey.match(/^(-)?(.*)$/) ?? [];
                dispatch({
                  type: 'SET_SORT',
                  sort: { sort, sortDirection: desc ? 'desc' : 'asc' },
                });
                track('history-sort::clicked', { key: sortKey });
              }}
              page={page}
              onPageChange={(page) => {
                dispatch({ type: 'SET_PAGE', page });
                track('history-page::clicked');
              }}
              limit={limit}
              onLimitChange={(limit) => {
                dispatch({ type: 'SET_LIMIT', limit });
                track('history-page-size::clicked', { size: limit });
              }}
              chartData={chartData}
              awardedData={paginatedClears}
              awardedDataFetching={fetchingPaginatedClears}
              pendingData={pendingData}
              pendingDataFetching={fetchingPendingData}
              stats={stats}
              historyType="market"
              rangeHasClearHistory={hasClearHistory}
            />
          </>
        )}
      </div>
      <CreditNoteModal
        handleDownload={handleCreditNoteDownload}
        onClose={closeCreditNoteModal}
        open={!!creditNoteDownloadUrl}
      />
    </>
  );
}

export default function MarketHistoryPage() {
  return (
    <QueryBoundaries LoadingComponent={() => <Skeleton />}>
      <MarketHistory />
    </QueryBoundaries>
  );
}
