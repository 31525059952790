import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { GlobalOfferInputDto } from '@/generated/gql/graphql';
import { paths } from '@/generated/supplier-experience-api';
import { TakerMarket } from '@/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import useFeature, { Features } from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useTakerMarketsGroups from './useTakerMarketsGroups';

interface GlobalPriceDiscoveryArgs {
  isDiscountBidding?: boolean;
  isEnabled?: boolean;
  maxApr?: number;
  maxDiscount?: number;
}

export const SET_PRICE_DISCOVERY_GLOBAL_OFFER = graphql(`
  mutation SetPriceDiscoveryGlobalOffer($input: GlobalOfferInputDto!) {
    setPriceDiscoveryGlobalOffer(input: $input) {
      failures {
        supplierDivisionUuid
        marketUuid
        error
      }
      successes {
        marketUuid
        supplierDivisionUuid
      }
    }
  }
`);

const updateGlobalPriceDiscoveryOfferSEA = async (
  takerMarkets: TakerMarket[] | undefined,
  isEnabled: boolean | undefined,
  rate: number | undefined,
  rateType: 'APR' | 'DISC'
) => {
  if (takerMarkets === undefined) {
    return;
  }

  // Update all price discovery offers that are in SEA already
  const offers = takerMarkets
    .filter(
      (tm) =>
        tm.offerConfig.uuid !== undefined &&
        (tm.isTpf === undefined || tm.isTpf === false) &&
        tm.offerConfig.offerType !== 'PREFERRED_TERM' &&
        tm.offerConfig.offerType !== 'EXPRESS_ACCEPT' &&
        tm.marketIsEnabled === true
    )
    .map((tm) => ({ uuid: tm.offerConfig.uuid!, makerOrganizationUuid: tm.makerOrganizationUuid }));

  const status = isEnabled !== undefined ? (isEnabled ? 'active' : 'paused') : undefined;

  const rateInformation = rate !== undefined ? { rateType, rate } : undefined;

  await apiClient.patch('api/supplier-experience/offers/price-discovery', {
    json: {
      offers: offers,
      status,
      rateInformation,
    } satisfies paths['/offers/price-discovery']['patch']['requestBody']['content']['application/json'],
  });

  // Return taker/market so that the frontend can map back to updated taker markets
  return offers.map((offer) => {
    const takerMarket = takerMarkets.find((tm) => tm.offerConfig.uuid === offer.uuid)!;
    return {
      marketUuid: takerMarket.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
    };
  });
};

/**
 * used for both global offer and global participation submits
 * global offer submit sets only rate and type
 * global participation sets only enabled
 */
const useGlobalPriceDiscoveryOffer = () => {
  const queryClient = useQueryClient();
  const {
    NAME_YOUR_RATE: { takerMarkets },
  } = useTakerMarketsGroups();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const [enableSEA] = useFeature(Features['enterprise-ui_enableSeaWrites']);

  return useMutation({
    mutationKey: ['setPriceDiscoveryGlobalOffer'],
    mutationFn: async ({ isDiscountBidding, isEnabled, maxApr, maxDiscount }: GlobalPriceDiscoveryArgs) => {
      if (enableSEA) {
        return updateGlobalPriceDiscoveryOfferSEA(
          takerMarkets,
          isEnabled,
          isDiscountBidding ? maxDiscount : maxApr,
          isDiscountBidding ? 'DISC' : 'APR'
        );
      }

      const input: GlobalOfferInputDto =
        typeof isEnabled !== 'undefined'
          ? {
              enabled: isEnabled,
            }
          : {
              rate: isDiscountBidding ? maxDiscount : maxApr,
              type: isDiscountBidding ? 'DISC' : 'APR',
            };

      const data = await gqlClient.request(SET_PRICE_DISCOVERY_GLOBAL_OFFER, { input });

      return data.setPriceDiscoveryGlobalOffer.successes;
    },
    onSuccess: async (data, { isDiscountBidding, isEnabled, maxDiscount, maxApr }) => {
      const updatedTakerMarkets = data ?? [];

      // update the cache with the returned data
      queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
        (prevTakerMarkets ?? []).map((tm) => {
          const updatedData = updatedTakerMarkets.find(
            (tmData) => tmData.marketUuid === tm.marketUuid && tmData.supplierDivisionUuid === tm.takerDivisionUuid
          );

          if (updatedData) {
            const offerConfigUpdate =
              typeof isEnabled !== 'undefined'
                ? {
                    isEnabled,
                  }
                : {
                    isDiscountBidding,
                    ...(!isDiscountBidding && { maxApr }),
                    ...(isDiscountBidding && { maxDiscount }),
                  };

            return {
              ...tm,
              offerConfig: {
                ...tm.offerConfig,
                ...offerConfigUpdate,
              },
            };
          }

          return tm;
        })
      );

      // subscribe to market stats for each updated taker market
      // this will refetch stats queries after we know stats have updated
      updatedTakerMarkets.forEach((tm) => {
        // map tm uuid to get taker division id for subscribing to events
        const tmMeta = takerMarkets?.find(
          (meta) => meta.marketUuid === tm.marketUuid && meta.takerDivisionUuid === tm.supplierDivisionUuid
        );

        if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
          subscribeToMarketStats({
            marketUuid: tmMeta.marketUuid,
            takerId: tmMeta.takerDivisionId,
          });
        }
      });
    },
  });
};

export default useGlobalPriceDiscoveryOffer;
