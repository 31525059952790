import { TakerMarket } from '@/data/useTakerMarkets';
import { RecurringRuleFormValues } from '../RecurringRulesForm';
import { mapFormCriteriaToRuleRecord, RuleRecord } from './utils';

export interface RuleTakerMarket extends Omit<TakerMarket, 'marketId'> {
  marketId: number;
  takerId: number;
  takerUuid: string;
}

interface RuleByMakerOrg {
  [makerOrganizationUuid: string]: {
    [currency: string]: {
      takerMarkets: RuleTakerMarket[];
    } & Partial<RuleRecord>;
  };
}

/**
 * utility function for grouping rules data submitted via recurring rules form by maker organization
 */
const groupRulesByMakerOrg = (data: RecurringRuleFormValues): RuleByMakerOrg =>
  data.takerMarkets.reduce((rulesByMakerOrg: RuleByMakerOrg, takerMarket: TakerMarket) => {
    const { currency, makerOrganizationUuid } = takerMarket;
    const takerMarketMeta = {
      ...takerMarket,
      marketId: takerMarket.legacyMarketId,
      takerId: takerMarket.takerDivisionId,
      takerUuid: takerMarket.takerDivisionUuid,
    };

    // map criteria from form values to rule format for backend
    const ruleMeta = mapFormCriteriaToRuleRecord(data.criteria);
    const makerOrganizationUuidFound = Object.keys(rulesByMakerOrg).includes(makerOrganizationUuid);

    if (makerOrganizationUuidFound) {
      if (rulesByMakerOrg[makerOrganizationUuid][currency]) {
        rulesByMakerOrg[makerOrganizationUuid][currency].takerMarkets.push(takerMarketMeta);
      } else {
        rulesByMakerOrg[makerOrganizationUuid][currency] = {
          takerMarkets: [takerMarketMeta],
          ...ruleMeta,
        };
      }
    } else {
      rulesByMakerOrg[makerOrganizationUuid] = {
        [currency]: {
          takerMarkets: [takerMarketMeta],
          ...ruleMeta,
        },
      };
    }

    return rulesByMakerOrg;
  }, {});

export default groupRulesByMakerOrg;
