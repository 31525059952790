import { RecurringRuleArgument } from '@/data/useCreateRecurringRule';
import { RecurringRule, RecurringRuleCategory, RecurringRuleCriteria } from '@/data/useRecurringRules';
import { UpdateRecurringRuleArgument } from '@/data/useUpdateRecurringRule';
import { RecurringRuleFormCriteriaValue } from '../RecurringRulesForm';

export type RuleRecord = Record<RecurringRuleCriteria, string>;

/**
 * maps an array of form criteria to a rule record object
 * input = [{ type: 'gteDpe', value: '8' }, { type: 'lteDpe', value: '24' }]
 * output = { gteDpe: '8', lteDpe: '24' }
 */
export const mapFormCriteriaToRuleRecord = (formCriteria: RecurringRuleFormCriteriaValue[]): RuleRecord => {
  return formCriteria.reduce((ruleRecord: RuleRecord, formCriteria: RecurringRuleFormCriteriaValue) => {
    ruleRecord[formCriteria.type] = formCriteria.value;
    return ruleRecord;
  }, {} as RuleRecord);
};

/**
 * maps a formatted recurring rule object to an object containing arrays of form criteria by rule category
 * input = { gteDpe: 8, lteDpe: 24, lteInvoiceAmount: 1000 }
 * output = {
 *   dpe: [{ type: 'gteDpe', value: '8' }, { type: 'lteDpe', value: '24' }],
 *   amount: [{ type: 'lteInvoiceAmount', value: '1000' }]
 * }
 */
export const mapRecurringRuleToFormCriteria = (
  recurringRule: RecurringRule
): Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue[]> => {
  const formCritera = {} as Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue[]>;

  if (recurringRule.dpe) {
    formCritera.dpe = [
      ...(recurringRule.dpe.gteDpe ? [{ type: 'gteDpe', value: recurringRule.dpe.gteDpe.toString() }] : []),
      ...(recurringRule.dpe.lteDpe ? [{ type: 'lteDpe', value: recurringRule.dpe.lteDpe.toString() }] : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.dueDate) {
    formCritera.dueDate = [
      ...(recurringRule.dueDate.fromDueDate
        ? [{ type: 'fromDueDate', value: recurringRule.dueDate.fromDueDate.toString() }]
        : []),
      ...(recurringRule.dueDate.toDueDate
        ? [{ type: 'toDueDate', value: recurringRule.dueDate.toDueDate.toString() }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.amount) {
    formCritera.amount = [
      ...(recurringRule.amount.gteInvoiceAmount
        ? [{ type: 'gteInvoiceAmount', value: recurringRule.amount.gteInvoiceAmount.toString() }]
        : []),
      ...(recurringRule.amount.lteInvoiceAmount
        ? [{ type: 'lteInvoiceAmount', value: recurringRule.amount.lteInvoiceAmount.toString() }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.excludedVoucherIds) {
    formCritera.invoiceId = [
      ...(recurringRule.excludedVoucherIds
        ? [{ type: 'excludedVoucherIds', value: recurringRule.excludedVoucherIds.join(', ') }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  return formCritera;
};

/**
 * maps a rule record object to the format and data types expected by the API
 * input = { gteDpe: '8', lteDpe: '24' }
 * output = { gteDpe: 8, lteDpe: 24 }
 */
export const mapRuleRecordToSubmit = (
  ruleRecord: Partial<RuleRecord>
): Omit<RecurringRuleArgument, 'makerOrganizationUuid' | 'takersMarkets'> => ({
  ...(ruleRecord.lteDpe && { lteDpe: Number(ruleRecord.lteDpe) }),
  ...(ruleRecord.gteDpe && { gteDpe: Number(ruleRecord.gteDpe) }),
  ...(ruleRecord.lteInvoiceAmount && { lteInvoiceAmount: Number(ruleRecord.lteInvoiceAmount) }),
  ...(ruleRecord.gteInvoiceAmount && { gteInvoiceAmount: Number(ruleRecord.gteInvoiceAmount) }),
  ...(ruleRecord.fromDueDate && { fromDueDate: ruleRecord.fromDueDate }),
  ...(ruleRecord.toDueDate && { toDueDate: ruleRecord.toDueDate }),
  ...(ruleRecord.excludedVoucherIds && { excludedVoucherIds: [ruleRecord.excludedVoucherIds] }),
});

type RuleKey =
  | 'lteDpe'
  | 'gteDpe'
  | 'lteInvoiceAmount'
  | 'gteInvoiceAmount'
  | 'fromDueDate'
  | 'toDueDate'
  | 'excludedVoucherIds';

export const mapRuleArgumentToRuleArray = (
  rule: RecurringRuleArgument | UpdateRecurringRuleArgument
): { type: RuleKey }[] => {
  return Object.keys(rule)
    .filter((key) =>
      [
        'lteDpe',
        'gteDpe',
        'lteInvoiceAmount',
        'gteInvoiceAmount',
        'fromDueDate',
        'toDueDate',
        'excludedVoucherIds',
      ].includes(key)
    )
    .map((key) => ({ type: key as RuleKey }));
};
