import { useQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { QueryEarlyPayDraftOfferByUuidArgs } from '@/generated/gql/graphql';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import useFeature, { Features } from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';

const GET_DRAFT_OFFER = graphql(`
  query EarlyPayDraftOfferByUuid($uuid: String!) {
    earlyPayDraftOfferByUuid(uuid: $uuid) {
      created
      draftType
      expirationDate
      rate
      rateType
      spread
      marketUuid
      status
      submitterAuthServiceUuid
      submitterSystemName
      supplierDivisionUuids
      termExpirationDate
      type
      uuid
      authServiceUuid
    }
  }
`);

async function fetchDraftOffer(variables: QueryEarlyPayDraftOfferByUuidArgs) {
  const res = await gqlClient.request(GET_DRAFT_OFFER, variables);
  const supplierMarkets =
    (res.earlyPayDraftOfferByUuid?.supplierDivisionUuids
      .map((supplierDivisionUuid) => {
        return {
          marketUuid: res.earlyPayDraftOfferByUuid?.marketUuid,
          supplierDivisionUuid,
        };
      })
      .filter((supplierMarket) => {
        return supplierMarket.marketUuid !== undefined;
      }) as { supplierDivisionUuid: string; marketUuid: string }[]) ?? [];

  if (!res.earlyPayDraftOfferByUuid) {
    return null;
  }

  return {
    ...res.earlyPayDraftOfferByUuid,
    supplierMarkets,
  };
}

async function fetchDraftOfferSEA(uuid: string) {
  const res = await apiClient
    .get(`api/supplier-experience/offers/draft/${uuid}`)
    .json<paths['/offers/draft/{uuid}']['get']['responses']['200']['content']['application/json']>();

  const spread =
    res.data.rateInformation !== null && 'spread' in res.data.rateInformation
      ? res.data.rateInformation.spread ?? null
      : null;
  const type =
    res.data.offerType === 'preferred-term'
      ? 'PREFERRED'
      : res.data.offerType === 'price-discovery'
      ? 'PRICE_DISCOVERY'
      : undefined;

  return {
    draftType: res.data.draftType,
    expirationDate: res.data.expirationDate,
    rate: res.data.rateInformation?.rate,
    rateType: res.data.rateInformation?.rateType,
    spread,
    status: res.data.status,
    submitterAuthServiceUuid: res.data.submitterUuid,
    submitterSystemName: res.data.submitterSystemName,
    supplierMarkets: res.data.supplierMarketGroups.map((group) => {
      return group.supplierMarkets[0];
    }),
    termExpirationDate: res.data.offerExpirationDate,
    type,
    uuid,
    authServiceUuid: res.data.authServiceUuid,
  };
}

export default function useDraftOffer(uuid: string | undefined) {
  const [enableSEA] = useFeature(Features['enterprise-ui_enableSeaDraftOffers']);
  return useQuery({
    enabled: !!uuid,
    queryKey: ['draft-offer', uuid],
    // Asserting with ! as we won't even enable the query without a uuid
    queryFn: async () => {
      if (enableSEA) {
        return fetchDraftOfferSEA(uuid!);
      }

      return fetchDraftOffer({ uuid: uuid! });
    },
  });
}
