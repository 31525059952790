import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import { UpdateRecurringRuleArgument } from './useUpdateRecurringRule';

/**
 * hook for deleting a recurring rule and all its criteria
 */
export interface DeleteRecurringRuleArgument {
  id: UpdateRecurringRuleArgument['id'];
  makerOrganizationUuid: UpdateRecurringRuleArgument['makerOrganizationUuid'];
  marketId: UpdateRecurringRuleArgument['marketId'];
  takerId: UpdateRecurringRuleArgument['takerId'];
}

const useDeleteRecurringRule = () => {
  const { track } = useReporting();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteRecurringRule'],
    mutationFn: (argument: DeleteRecurringRuleArgument) => {
      const { id, makerOrganizationUuid } = argument;

      return apiClient.delete(
        `api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules/${id}`
      );
    },
    onSuccess: (_, variables) => {
      const { makerOrganizationUuid, marketId, takerId } = variables;

      track('recurring-rules::deleted', {
        makerOrganizationUuid,
        takerMarkets: [{ marketId, takerId }],
      });

      queryClient.refetchQueries(['recurring-rules', makerOrganizationUuid]);
    },
  });
};

export default useDeleteRecurringRule;
