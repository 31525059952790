import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { CircleCheckIcon } from '@c2fo/liquidity/icons';

const RequestInformationModal = ({ open = false, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <ModalTitleInfo>{t('preferredMarketing.modal.requestSubmitted')}</ModalTitleInfo>
      <ModalContent center>
        <CircleCheckIcon className="mx-auto mb-3 h-[70px] w-auto" />
        <p>{t('recurringRules.modal.requestSubmitted.description')}</p>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={onClose}>
          {t('core.close')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default RequestInformationModal;
