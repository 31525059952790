import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { PlusIcon } from '@c2fo/liquidity/icons';
import { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';
import { RecurringRuleFormValues } from '../RecurringRulesForm';
import { mapRecurringRuleToFormCriteria } from '../utils/utils';

const QuickActionCreateRule = ({
  ruleCategory,
  rule,
  onClick,
  condensed = false,
}: {
  ruleCategory: RecurringRuleCategory;
  rule?: RecurringRule;
  onClick: (defaultValues: Partial<RecurringRuleFormValues>) => void;
  condensed?: boolean;
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const { t } = useTranslation();
  let title = '';

  const existingCriteria = rule ? mapRecurringRuleToFormCriteria(rule)[ruleCategory] : undefined;
  const defaultValues: Partial<RecurringRuleFormValues> = {
    category: ruleCategory,
    ...(existingCriteria && { criteria: existingCriteria }),
  };

  switch (ruleCategory) {
    case 'dpe':
      title = condensed ? t('recurringRules.dpe.title') : t('recurringRules.dpeRule');
      break;
    case 'invoiceId':
      title = condensed ? t('recurringRules.invoiceId.title') : t('recurringRules.invoiceIdRule');
      break;
    case 'amount':
      title = condensed ? t('recurringRules.invoiceAmount.title') : t('recurringRules.invoiceAmountRule');
      break;
    case 'dueDate':
      title = condensed ? t('recurringRules.dueDate.title') : t('recurringRules.dueDateRule');
      break;
  }

  return condensed ? (
    <Popover open={openTooltip}>
      <Popover.Trigger
        onClick={(e) => {
          e.preventDefault();
          onClick(defaultValues);
        }}
      >
        <div onMouseEnter={() => setOpenTooltip(true)} onMouseLeave={() => setOpenTooltip(false)}>
          <PlusIcon fill={colors.secondary[500]} />
        </div>
      </Popover.Trigger>
      <Popover.Content arrow>{title}</Popover.Content>
    </Popover>
  ) : (
    <button type="button" className="flex items-center gap-2" onClick={() => onClick(defaultValues)}>
      <PlusIcon fill={colors.secondary[500]} className="shrink-0" />
      <div className="text-sm font-bold text-deepBlue-500">{title}</div>
    </button>
  );
};

export default QuickActionCreateRule;
