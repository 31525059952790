import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '@c2fo/liquidity';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import useSelectedCurrency from '@/utils/useSelectedCurrency';

export interface RuleExcludedInvoicesAlertProps {
  takerMarkets: TakerMarket[];
  excludedAmount: number;
  currency?: string;
  tab?: TakerMarketGroupType;
}

const RuleExcludedInvoicesAlert = ({ takerMarkets, currency, excludedAmount, tab }: RuleExcludedInvoicesAlertProps) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const selectedCurrency = useSelectedCurrency();
  const navigate = useNavigate();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions(takerMarkets);
  const showAction = canEditRecurringRules && !!tab;
  const isPreferred = takerMarkets.every((takerMarket) => takerMarket.offerConfig.offerType === 'PREFERRED_TERM');

  if (takerMarkets.length === 0) {
    return null;
  }

  const description = (
    <div>
      <span>
        {t('recurringRules.excludedAmountAlert', {
          amount: asCurrency(excludedAmount, currency ?? selectedCurrency),
        })}
      </span>
      {isPreferred && <span> {t('recurringRules.preferredOfferLockedDescription')}</span>}
    </div>
  );

  return (
    <Alert
      description={description}
      full
      type="info"
      variant="outlined"
      {...(showAction && {
        action: {
          onClick: () => navigate('/supplier/recurring-rules'),
          text: t('recurringRules.manageRules'),
        },
      })}
    />
  );
};

export default RuleExcludedInvoicesAlert;
