import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { StaticOfferInput } from '@/generated/gql/graphql';
import { paths } from '@/generated/supplier-experience-api';
import useTakerMarkets, { TakerMarket } from '@/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';
import { gqlClient } from '@/lib/gqlClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';

export const SUBMIT_STATIC_OFFER = graphql(`
  mutation SubmitStaticOffer($offer: StaticOfferInput!) {
    submitStaticOffer(offer: $offer)
  }
`);

const useSubmitStaticOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const [enableSeaApi] = useFeature('enterprise-ui_enableSeaWrites');

  return useMutation({
    mutationKey: ['submitStaticOffer'],
    mutationFn: async ({
      rate,
      marketUuid,
      supplierDivisionUuid,
    }: {
      rate: number;
      marketUuid: string;
      supplierDivisionUuid: string;
    }) => {
      const takerMarket = takerMarkets?.find(
        (tm) => tm.marketUuid === marketUuid && tm.takerDivisionUuid === supplierDivisionUuid
      );

      if (enableSeaApi) {
        const uuid = takerMarket?.offerConfig.uuid;

        if (uuid) {
          return apiClient
            .patch('api/supplier-experience/offers/static', {
              json: {
                groupingKey: 'SUPPLIER_MARKET',
                status: 'active',
                rateInformation: {
                  rateType: 'APR',
                  rate: rate,
                },
                offers: [
                  {
                    makerOrganizationUuid: takerMarket?.makerOrganizationUuid,
                    uuid,
                  },
                ],
                // expirationDate: offerConfig.expireOn, - TODO in future PR
              } satisfies paths['/offers/static']['patch']['requestBody']['content']['application/json'],
            })
            .json<paths['/offers/static']['patch']['responses']['204']['content']>();
        }

        return apiClient
          .post('api/supplier-experience/offers/static', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              offerType: 'static',
              frequency: 'recurring',
              rateType: 'APR',
              rate: rate,
              supplierMarkets: [
                {
                  marketUuid,
                  supplierDivisionUuid,
                },
              ],
              // expirationDate: offerConfig.expireOn, - TODO in future PR
            } satisfies paths['/offers/static']['post']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/static']['post']['responses']['201']['content']['application/json']>();
      }

      const offer: StaticOfferInput = {
        apr: rate,
        excludeNewInvoices: takerMarket?.offerConfig.exclusionSettings.excludeNewInvoices ?? false,
        frequency: 'RECURRING',
        marketUuid,
        supplierDivisionUuid,
      };

      return gqlClient.request(SUBMIT_STATIC_OFFER, { offer });
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => ({
            ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
              ? {
                  ...tm,
                  offerConfig: {
                    ...tm.offerConfig,
                    maxApr: variables.rate,
                    isEnabled: true,
                  },
                }
              : tm),
          }))
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }
    },
  });
};

export default useSubmitStaticOffer;
