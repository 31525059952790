import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import { ExchangeRates } from '@/data/useExchangeRates';
import { RecurringRuleCriteria } from '@/data/useRecurringRules';
import { useReporting } from '@/reporting';
import convertCurrency from '@/utils/convertCurrency';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRecurringRuleFormContext from '../utils/useRecurringRuleFormContext';
import useRuleFormContent from '../utils/useRuleFormContent';

const ReviewRule = ({
  exchangeRates,
  toCurrency,
  showError,
  errorTakerMarkets,
}: {
  exchangeRates: ExchangeRates;
  toCurrency: string;
  showError: boolean;
  errorTakerMarkets: { takerId: number; marketId: number }[];
}) => {
  const { track } = useReporting();
  const { t } = useTranslation();
  const { asCurrency, asNumber } = useLocaleFormat();
  const { getValues } = useRecurringRuleFormContext();
  const [category, criteria, takerMarkets] = getValues(['category', 'criteria', 'takerMarkets']);
  const { getRuleFormContent } = useRuleFormContent();
  const { review } = getRuleFormContent(category);
  const [contactSRM, setContactSRM] = useState(false);

  const selectedDivisionCount = takerMarkets.length;
  const selectedDivisionText =
    selectedDivisionCount === 1
      ? t('core.divisionSelected', { selectedDivisionCount: asNumber({ value: selectedDivisionCount }) })
      : t('core.divisionsSelected', { selectedDivisionCount: asNumber({ value: selectedDivisionCount }) });

  const formatValue = ({ type, value }: { type: RecurringRuleCriteria; value: string }) => {
    if (type === 'gteInvoiceAmount' || type === 'lteInvoiceAmount') {
      return asCurrency(Number(value), toCurrency);
    } else if (type === 'gteDpe' || type === 'lteDpe') {
      return asNumber({ value: Number(value) });
    } else {
      return value;
    }
  };

  const uniqueSelectedCurrencies = [...new Set(takerMarkets.map(({ currency }) => currency))].filter(
    (currency) => currency !== toCurrency
  );

  const takerMarketsWithErrors = errorTakerMarkets
    .map(({ takerId, marketId }) => {
      return takerMarkets.find((tm) => tm.takerDivisionId === takerId && tm.legacyMarketId === marketId);
    })
    .filter((tm) => !!tm);

  return (
    <>
      {showError &&
        (contactSRM ? (
          <Alert type="info" title={t('core.contactSrm.title')} description={t('core.contactSrm.description')} full />
        ) : (
          <Alert
            type="error"
            title={
              selectedDivisionCount === 1
                ? t('recurringRules.error.createRule')
                : t('recurringRules.error.createRuleForDivisions')
            }
            description={t('recurringRules.error.createRuleTryAgain')}
            action={{
              text: t('core.contactSrm'),
              onClick: () => {
                // fire track event for slack channel
                track('recurring-rules::error', { type: 'create', category, errorTakerMarkets });
                setContactSRM(true);
              },
            }}
            full
          />
        ))}
      <div>
        <div className="mb-2 font-medium">{t('core.summary')}</div>
        <div className="rounded-md bg-canvas text-sm">
          <div className="flex justify-between gap-4 border-b-[0.5px] border-stroke/30 p-4">
            <div>{t('core.divisions')}</div>
            <div className="flex items-center font-bold">
              {takerMarketsWithErrors.length > 0 && (
                <Popover>
                  <Popover.Trigger>
                    <div className="pr-2">
                      <InfoCircleIcon className="h-4 w-4" fill={colors.error[500]} />
                    </div>
                  </Popover.Trigger>
                  <Popover.Content arrow side="bottom">
                    <div className="space-y-2 text-sm">
                      <div>{t('recurringRules.error.divisionsListLabel')}</div>
                      {takerMarketsWithErrors.map((tm) => (
                        <div key={`${tm.takerDivisionId}-${tm.legacyMarketId}`}>
                          <div>
                            {getTakerMarketName(tm)} - {tm.currency}
                          </div>
                          <div>{getTakerMarketDivisionTitle(tm)?.content}</div>
                        </div>
                      ))}
                    </div>
                  </Popover.Content>
                </Popover>
              )}
              {selectedDivisionText}
            </div>
          </div>
          <div className="flex items-center justify-between gap-4 p-4">
            <div className="w-2/5">{t('recurringRules.rule')}</div>
            <div className="w-3/5 flex-wrap space-y-2 text-right">
              {criteria.map(({ type, value }) => (
                <Fragment key={type}>
                  {review[type] ? (
                    <div className="font-bold">{t(review[type], { value: formatValue({ type, value }) })}</div>
                  ) : null}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {uniqueSelectedCurrencies.length > 0 && category === 'amount' && (
        <div>
          <Alert
            type="default"
            title={t('recurringRules.reviewRule.currencyConversionTitle')}
            description={
              <>
                <div>
                  {uniqueSelectedCurrencies.length === 1
                    ? t('recurringRules.reviewRule.currencyConversionDescriptionSingle', { currency: toCurrency })
                    : t('recurringRules.reviewRule.currencyConversionDescriptionMultiple', { currency: toCurrency })}
                </div>
                <ul className="ml-4 mt-4 list-disc space-y-1">
                  {uniqueSelectedCurrencies.map((currency) => (
                    <li key={currency} className="space-x-1">
                      <span className="font-bold">
                        {currency} to {toCurrency}
                      </span>
                      <span>
                        {`(${asCurrency(
                          convertCurrency({
                            amount: 1,
                            exchangeRates,
                            from: toCurrency,
                            to: currency,
                          }),
                          currency
                        )} to ${asCurrency(
                          convertCurrency({
                            amount: 1,
                            exchangeRates,
                            from: currency,
                            to: currency,
                          }),
                          toCurrency
                        )})`}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        </div>
      )}
      <div>
        <ul className="ml-6 list-disc">
          <li>{t('recurringRules.reviewRule.listItem1')}</li>
          <li>{t('recurringRules.reviewRule.listItem2')}</li>
          <li>{t('recurringRules.reviewRule.listItem3')}</li>
          <li>{t('recurringRules.reviewRule.listItem4')}</li>
          <li>{t('recurringRules.reviewRule.listItem5')}</li>
          <li>{t('recurringRules.reviewRule.listItem6')}</li>
        </ul>
      </div>
    </>
  );
};

export default ReviewRule;
