import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';
import { useSnackbarDispatchContext } from '@c2fo/liquidity';
import { useServerSideEventListeners } from '@/lib/serverSentEvents/useServerSideEventListeners';

/**
 * show snackbar for "calculating your markets" if waiting for stats
 */
const CalculatingMarketSnackbar = () => {
  const { t } = useTranslation();
  const [showCalculatingMarketsSnackbar, setShowCalculatingMarketsSnackbar] = useState(false);
  const { listenToAllMarketStats } = useServerSideEventListeners();
  const dispatch = useSnackbarDispatchContext();

  const messageId = 'calculating-market-stats';

  useInterval(() => {
    const listeningForStats = listenToAllMarketStats();

    if (listeningForStats) {
      if (!showCalculatingMarketsSnackbar) {
        setShowCalculatingMarketsSnackbar(true);
        dispatch?.({
          type: 'enqueue',
          message: {
            id: messageId,
            closeable: false,
            content: t('taker.marketFeedback.calculatingYourMarkets'),
            loading: true,
            open: true,
          },
        });
      }
    } else {
      if (showCalculatingMarketsSnackbar) {
        setShowCalculatingMarketsSnackbar(false);
        dispatch?.({ type: 'dequeue', id: messageId });
      }
    }
  }, 1000);

  return null;
};

export default CalculatingMarketSnackbar;
