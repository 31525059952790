import { TableSortDirection } from '@/components/Table';
import { TakerMarketWithRecurringRule } from '@/data/useRecurringRules';

export type SortKey = 'takerMarketName' | 'eligibleInvoiceAmount';
export type Sort = { key: SortKey; direction: TableSortDirection } | null;

const sortRulesTable = (rules: TakerMarketWithRecurringRule[] = [], sort: Sort) => {
  // should default to eligibleInvoiceAmount desc
  if (!sort?.key || !sort?.direction) {
    return rules;
  }

  return rules.sort((a, b) => {
    const divisionNameA = a.takerMarket.marketType
      ? `${a.takerMarket.makerDivisionName}-${a.takerMarket.legacyMarketId}`
      : a.takerMarket.makerDivisionName;
    const divisionNameB = b.takerMarket.marketType
      ? `${b.takerMarket.makerDivisionName}-${b.takerMarket.legacyMarketId}`
      : b.takerMarket.makerDivisionName;

    if (sort.key === 'takerMarketName') {
      return sort.direction === 'desc'
        ? divisionNameB.localeCompare(divisionNameA)
        : divisionNameA.localeCompare(divisionNameB);
    }

    if (sort.key === 'eligibleInvoiceAmount') {
      return sort.direction === 'desc'
        ? b.takerMarket.eligibleInvoiceAmount - a.takerMarket.eligibleInvoiceAmount ||
            divisionNameA.localeCompare(divisionNameB)
        : a.takerMarket.eligibleInvoiceAmount - b.takerMarket.eligibleInvoiceAmount ||
            divisionNameA.localeCompare(divisionNameB);
    }

    return sort.direction === 'desc'
      ? b.takerMarket.eligibleInvoiceAmount - a.takerMarket.eligibleInvoiceAmount ||
          divisionNameA.localeCompare(divisionNameB)
      : a.takerMarket.eligibleInvoiceAmount - b.takerMarket.eligibleInvoiceAmount ||
          divisionNameA.localeCompare(divisionNameB);
  });
};

export default sortRulesTable;
