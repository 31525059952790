import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { AggregateTakerMarketStats } from '../utils/normalizeStats';

const StatsTable = ({ currency, stats }: { currency: string; stats: AggregateTakerMarketStats }) => {
  const { t } = useTranslation();
  const { asCurrency, asNumber, asPercent } = useLocaleFormat();
  const { listenToAllMarketStats } = useServerSideEventListeners();
  const listeningForMarketStats = listenToAllMarketStats();

  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full">
        <table className="min-w-full">
          <thead className="text-sm font-bold capitalize">
            <tr>
              <th scope="col" className="pr-4 text-left">
                {t('core.earlyPaymentStatus')}
              </th>
              <th scope="col" className="px-4 text-right">
                {t('core.amount')}
              </th>
              <th scope="col" className="px-4 text-right">
                {t('core.discount')}
              </th>
              <th scope="col" className="px-4 text-right">
                {t('core.dpe')}
              </th>
              <th scope="col" className="px-4 text-right">
                {t('maker.invoices')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-white last:border-none">
              <td className="w-full whitespace-nowrap p-4 pl-0 text-left">
                <div className="flex">
                  <div className="mr-3 h-auto w-1 bg-primary-500" />
                  <div>
                    <div className="text-2xl">
                      {t('aggregateStats.acceptedTitle', {
                        percent: listeningForMarketStats
                          ? '...%'
                          : asPercent({ value: stats.aggregateStats.acceptedPercent }),
                      })}
                    </div>
                    <div>{t('aggregateStats.acceptedSubtitle')}</div>
                  </div>
                </div>
              </td>
              <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                {asCurrency(stats.byCurrency[currency].acceptedInvoiceAmount, currency)}
              </td>
              <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                {asCurrency(stats.byCurrency[currency].acceptedEarn, currency)}
              </td>
              <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                {asNumber({ value: stats.byCurrency[currency].acceptedDpeWeightedAvg })}
              </td>
              <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                {asNumber({ value: stats.aggregateStats.acceptedInvoiceCount })}
              </td>
            </tr>
            {stats.aggregateStats.notAcceptedInvoiceCount > 0 && (
              <tr className="border-b border-white last:border-none">
                <td className="w-full whitespace-nowrap p-4 pl-0 text-left">
                  <div className="flex">
                    <div className="mr-3 h-auto w-1 bg-error-500" />
                    <div>
                      <div className="text-2xl">
                        {t('aggregateStats.notAcceptedTitle', {
                          percent: listeningForMarketStats
                            ? '...%'
                            : asPercent({ value: stats.aggregateStats.notAcceptedPercent }),
                        })}
                      </div>
                      <div>{t('aggregateStats.notAcceptedSubtitle')}</div>
                    </div>
                  </div>
                </td>
                <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                  {asCurrency(stats.byCurrency[currency].notAcceptedInvoiceAmount, currency)}
                </td>
                <td className="whitespace-nowrap p-4 text-right text-2xl">-</td>
                <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                  {asNumber({ value: stats.byCurrency[currency].notAcceptedDpeWeightedAvg })}
                </td>
                <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                  {asNumber({ value: stats.aggregateStats.notAcceptedInvoiceCount })}
                </td>
              </tr>
            )}
            {stats.aggregateStats.notParticipatingDivisionCount > 0 && (
              <tr className="border-b border-white last:border-none">
                <td className="w-full whitespace-nowrap p-4 pl-0 text-left">
                  <div className="flex">
                    <div className="mr-3 h-auto w-1 bg-gray-200" />
                    <div>
                      <div className="text-2xl capitalize">
                        {t(
                          stats.aggregateStats.notParticipatingDivisionCount === 1
                            ? 'aggregateStats.notParticipatingSingularTitle'
                            : 'aggregateStats.notParticipatingPluralTitle',
                          {
                            divisonCount: listeningForMarketStats
                              ? '...'
                              : stats.aggregateStats.notParticipatingDivisionCount,
                          }
                        )}
                      </div>
                      <div>{t('aggregateStats.notParticipatingSubtitle')}</div>
                    </div>
                  </div>
                </td>
                <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                  {asCurrency(stats.byCurrency[currency].notParticipatingInvoiceAmount, currency)}
                </td>
                <td className="whitespace-nowrap p-4 text-right text-2xl">-</td>
                <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                  {asNumber({ value: stats.byCurrency[currency].notParticipatingDpeWeightedAvg })}
                </td>
                <td className={cn('whitespace-nowrap p-4 text-right text-2xl', { 'blur-sm': listeningForMarketStats })}>
                  {asNumber({ value: stats.aggregateStats.notParticipatingInvoiceCount })}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StatsTable;
