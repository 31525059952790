import { useTranslation } from 'react-i18next';
import { Chip, ChipProps } from '@c2fo/liquidity';
import { RecurringRule } from '@/data/useRecurringRules';

export const RecurringRulesCountChip = ({
  count,
  excludedAmount,
  onClick,
  size = 'sm',
}: {
  count: number;
  excludedAmount?: string;
  onClick?: () => void;
  size?: ChipProps['size'];
}) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={
        <div className="text-left">
          <div>
            {count} {count === 1 ? t('recurringRules.recurringRule') : t('recurringRules.title')}
          </div>
          {excludedAmount && <div>{t('invoiceSettings.excludedAmount', { amount: excludedAmount })}</div>}
        </div>
      }
      size={size}
      type="info"
      variant="outlined"
      {...(onClick && { onClick })}
    />
  );
};

const RecurringRulesSummaryChip = ({
  excludedAmount,
  rule,
  onEdit,
}: {
  excludedAmount: string;
  onEdit: () => void;
  rule: RecurringRule;
}) => {
  if (!rule) {
    return null;
  }

  return (
    <div className="mt-1">
      <RecurringRulesCountChip count={rule.count} excludedAmount={excludedAmount} onClick={onEdit} size="xs" />
    </div>
  );
};

export default RecurringRulesSummaryChip;
