import useRecurringRules, { RecurringRule } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';

const useGetRecurringRulesForTakerMarkets = () => {
  const recurringRules = useRecurringRules();

  const getRecurringRulesForTakerMarkets = (takerMarkets: TakerMarket[]): RecurringRule[] => {
    return takerMarkets
      .map((takerMarket) => {
        return recurringRules.find(
          ({ rule }) =>
            rule.makerOrganizationUuid === takerMarket.makerOrganizationUuid &&
            rule.takerId === takerMarket.takerDivisionId &&
            rule.marketId === takerMarket.legacyMarketId
        );
      })
      .filter((rule) => !!rule)
      .map(({ rule }) => rule);
  };

  return { getRecurringRulesForTakerMarkets };
};

export default useGetRecurringRulesForTakerMarkets;
