import { Outlet, ScrollRestoration } from 'react-router-dom';
import { cn } from '@c2fo/liquidity';
import CalculatingMarketSnackbar from '@/components/CalculatingMarketSnackbar';
import DefaultCurrencyInitializer from '@/components/DefaultCurrencyInitializer';
import DefaultTranslationInitializer from '@/components/DefaultTranslationInitializer';
import FileDownloadModal from '@/components/FileDownloadModal';
import Footer from '@/components/Footer';
import { ImpersonationBanner } from '@/components/ImpersonationBanner';
import InactivityMonitor from '@/components/InactivityMonitor';
import Navigation from '@/components/Navigation';
import ReadOnlyBanner from '@/components/ReadOnlyBanner';
import { ServerSentEvents, ServerSentEventsInterval } from '@/lib/serverSentEvents';
import { useDataDogRumUserAccountIdInitialize } from '@/reporting/DataDog';
import Intercom from '@/reporting/Intercom';
import PageTracker from '@/reporting/PageTracker';
import { InitQualtrics } from '@/reporting/Qualtrics';
import { isImpersonationSession } from '@/utils/token';
import { useReadOnlyUser } from '@/utils/useRestrictions';

const App = () => {
  const readOnlyUser = useReadOnlyUser();
  const isImpersonation = isImpersonationSession();

  useDataDogRumUserAccountIdInitialize();

  return (
    <>
      <DefaultCurrencyInitializer />
      <DefaultTranslationInitializer />
      <CalculatingMarketSnackbar />
      <PageTracker />
      <ScrollRestoration />
      <InactivityMonitor />
      <ServerSentEvents />
      <ServerSentEventsInterval />
      <Intercom />
      <InitQualtrics />
      <FileDownloadModal />
      <div className="flex min-h-screen flex-col bg-canvas antialiased">
        {isImpersonation && <ImpersonationBanner />}
        {readOnlyUser && <ReadOnlyBanner isImpersonation={isImpersonation} />}
        <div
          className={cn('flex min-h-screen flex-col', {
            'mt-10': isImpersonation || readOnlyUser,
            'mt-20': isImpersonation && readOnlyUser,
          })}
        >
          <Navigation />
          <div className="mt-16 grow">
            <div className="mx-auto max-w-screen-2xl px-4 py-10 lg:px-6">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default App;
