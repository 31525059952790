import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo, cn, useSnackbar } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CircleCheckIcon, QuestionCircleIcon } from '@c2fo/liquidity/icons';
import PreferredLogo from '@/components/PreferredLogo';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import useSubmitInformationRequestPreferred from '@/data/useSubmitInformationRequestPreferred';
import { TakerMarket } from '@/data/useTakerMarkets';
import useTakerMarketsGroups from '@/data/useTakerMarketsGroups';
import useUserDetails from '@/data/useUserDetails';
import VariableRateInfoPopover from '@/features/variableRate/InfoPopover';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { useReporting } from '@/reporting';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { isPreferredOfferNearTermEnd, usePreferredOffersNearTermEnd } from '@/utils/preferredOfferRenewal';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import PreferredParticipationToggle from '../preferred/ParticipationToggle';
import PreferredAlertBanner from '../preferred/PreferredRenewalBanner';
import RenewalRequestIcon from '../preferred/RenewalRequestIcon';
import usePreferredRateDetails from '../preferred/usePreferredRateDetails';
import RecurringRulesForm from '../recurringRules/RecurringRulesForm';
import useEnableRecurringRules from '../recurringRules/utils/useEnableRecurringRules';
import useGetRecurringRulesForTakerMarkets from '../recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import { useSrm } from '../srmCard/SrmCard';
import VariableRateDetailModal from '../variableRate/DetailModal';
import NextMarketClearTimeCountdown from './components/NextMarketClearTimeCountdown';
import RecurringRulesSummaryChip from './components/RecurringRulesSummaryChip';
import sortTakerMarketTable, { Sort, SortKey } from './utils/sortTakerMarketTable';

const PreferredRateMarketTable = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const srm = useSrm();
  const { asCurrency, asNumber } = useLocaleFormat();
  const { listenToMarketStats } = useServerSideEventListeners();
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const { getPreferredRateDetails } = usePreferredRateDetails();
  const { hasPreferredOffersNearTermEnd } = usePreferredOffersNearTermEnd();
  const [showVariableRateDetails, setShowVariableRateDetails] = useState<TakerMarket | null>(null);
  const [openRateModal, setOpenRateModal] = useState<boolean>(false);
  const [showPreferredRequestModal, setShowPreferredRequestModal] = useState(false);
  const takerMarketsGroups = useTakerMarketsGroups();
  const { data: userData } = useUserDetails();
  const preferredTakerMarkets = sortTakerMarketTable(takerMarketsGroups['PREFERRED'].takerMarkets, sort);
  const enableRecurringRules = useEnableRecurringRules();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const [recurringRulesModalOpen, setRecurringRulesModalOpen] = useState(false);
  const [ruleModalProps, setRuleModalProps] = useState<{ takerMarkets: TakerMarket[] }>({ takerMarkets: [] });

  const showSnackbar = useSnackbar();
  const [preferredInfoRequestSubmitted, setPreferredInfoRequestSubmitted] = useState(false);
  const { mutateAsync: submitInformationRequestPreferred, isLoading: isLoadingSubmitInformationRequestPreferred } =
    useSubmitInformationRequestPreferred();

  const sendPreferredInfoRequest = async () => {
    try {
      track('preferred-marketing-request::clicked', {
        srmEmail: srm.emailAddress,
        accountId: userData?.me?.account?.id,
        userEmail: userData?.me?.emailAddress,
      });
      await submitInformationRequestPreferred();
      setShowPreferredRequestModal(true);
    } catch {
      showSnackbar({
        message: t('core.error'),
      });
    } finally {
      setPreferredInfoRequestSubmitted(true);
    }
  };

  const showPreferredMarketing = preferredTakerMarkets.length === 0;

  useEffect(() => {
    if (showPreferredMarketing) {
      track('preferred-marketing-request::shown');
    }
  }, [showPreferredMarketing, track]);

  const openVariableRateModal = (takerMarket: TakerMarket) => {
    setShowVariableRateDetails(takerMarket);
    setOpenRateModal(true);
  };

  const closeVariableRateModal = () => setOpenRateModal(false);

  const { formattedEstimatedRate, formattedDiscountAmount, formattedSpread, formattedReferenceRate, rateName } =
    getPreferredRateDetails(showVariableRateDetails);

  const handleSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'makerOrganizationName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const openRecurringRulesModal = (takerMarket: TakerMarket) => {
    setRuleModalProps({ takerMarkets: [takerMarket] });
    setRecurringRulesModalOpen(true);
    track('recurring-rules::opened', {
      referrer: 'homepage',
      source: 'preferred-rate-table',
    });
  };

  const closeRecurringRulesModal = () => {
    setRecurringRulesModalOpen(false);
    track('recurring-rules::closed');
  };

  const isMarketRateColumnShown = preferredTakerMarkets.some((tm) => {
    const { rateLabel } = getPreferredRateDetails(tm);
    return rateLabel !== undefined;
  });

  const tableHeadColumns: { accessorKey: SortKey; content: ReactNode }[] = [
    {
      accessorKey: 'participation',
      content: t('core.participation'),
    },
    {
      accessorKey: 'makerOrganizationName',
      content: t('core.division'),
    },
    {
      accessorKey: 'disableAfterMarketClearsDate',
      content: t('taker.dictionary.termEnd.label'),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      content: t('taker.dictionary.availableAR.label'),
    },
    {
      accessorKey: 'marketPayDate',
      content: t('taker.dictionary.payDate.label'),
    },
    ...(isMarketRateColumnShown
      ? [
          {
            accessorKey: 'rateInfo.referenceRate' as SortKey,
            content: <VariableRateInfoPopover iconColor={colors.primary[800]} />,
          },
        ]
      : []),
    {
      accessorKey: 'offerConfig',
      content: t('taker.dictionary.offer.label'),
    },
    {
      accessorKey: 'acceptedInvoiceAmount',
      content: t('taker.dictionary.clearing.label'),
    },
    {
      accessorKey: 'notAcceptedInvoiceAmount',
      content: t('taker.dictionary.nonClearing.label'),
    },
    {
      accessorKey: 'acceptedEarn',
      content: t('taker.dictionary.discount.label'),
    },
    {
      accessorKey: 'eligibleDpeWeightedAvg',
      content: t('taker.dictionary.dpe.label'),
    },
  ];

  return (
    <>
      {!showPreferredMarketing ? (
        <>
          {hasPreferredOffersNearTermEnd && (
            <div className="px-4">
              <PreferredAlertBanner />
            </div>
          )}
          <VariableRateDetailModal
            onCancel={closeVariableRateModal}
            open={openRateModal}
            formattedEstimatedRate={formattedEstimatedRate}
            formattedReferenceRate={formattedReferenceRate}
            formattedSpread={formattedSpread}
            formattedDiscountAmount={formattedDiscountAmount}
            rateName={rateName}
            showZeroApAlert={(showVariableRateDetails?.eligibleInvoiceAmount || 0) <= 0}
          />
          <RecurringRulesForm
            isForSingleDivision
            mode="readOnly"
            onClose={closeRecurringRulesModal}
            open={recurringRulesModalOpen}
            {...ruleModalProps}
          />
          <div className="w-full overflow-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  {tableHeadColumns.map(({ accessorKey, content }) => (
                    <TableHead
                      key={accessorKey}
                      onClick={() => handleSort(accessorKey)}
                      {...(['makerOrganizationName'].includes(accessorKey) && { className: 'max-w-[224px]' })}
                      {...(['participation'].includes(accessorKey) && { className: 'w-[170px]' })}
                    >
                      <TableSortArrow
                        accessorKey={accessorKey}
                        sort={sort}
                        {...(!['makerOrganizationName', 'participation'].includes(accessorKey) && { textRight: true })}
                      >
                        {content}
                      </TableSortArrow>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {preferredTakerMarkets?.map((takerMarket) => {
                  const listeningForMarketStat = listenToMarketStats([
                    {
                      marketUuid: takerMarket.marketUuid,
                      takerId: takerMarket.takerDivisionId,
                    },
                  ]);
                  const { formattedEstimatedRate, rateLabel, rateName } = getPreferredRateDetails(takerMarket);
                  const [recurringRule] = getRecurringRulesForTakerMarkets([takerMarket]);

                  return (
                    <TableRow
                      data-testid="takermarkettable.preferred.row"
                      key={takerMarket.id}
                      className={cn(
                        'transition-all duration-100',
                        takerMarket.id === showVariableRateDetails?.id && openRateModal && 'bg-gray-100'
                      )}
                    >
                      <TableCell>
                        <PreferredParticipationToggle variant="dark" />
                        {!takerMarket.marketIsEnabled && (
                          <div className="pt-1 text-xs text-gray-300">{t('closedMarketMessaging.futureOffer')}</div>
                        )}
                      </TableCell>
                      <TableCell className="max-w-[280px] truncate">
                        <div className="max-w-[280px] truncate">
                          <Link
                            className="font-bold"
                            to={`/supplier/markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                          >
                            {getTakerMarketName(takerMarket)}
                          </Link>
                        </div>
                        <div className="flex gap-0.5 text-sm font-medium text-gray-600">
                          <span>{takerMarket.currency}</span>
                          <span className="text-lg leading-4">&sdot;</span>
                          <NextMarketClearTimeCountdown takerMarket={takerMarket} />
                        </div>
                        <div
                          className="items-center truncate text-sm font-medium uppercase text-gray-600"
                          title={getTakerMarketDivisionTitle(takerMarket)?.title}
                        >
                          {getTakerMarketDivisionTitle(takerMarket)?.content}
                        </div>
                        {enableRecurringRules && (
                          <RecurringRulesSummaryChip
                            excludedAmount={asCurrency(takerMarket.takerExcludedInvoiceAmount, takerMarket.currency)}
                            onEdit={() => openRecurringRulesModal(takerMarket)}
                            rule={recurringRule}
                          />
                        )}
                      </TableCell>
                      <TableCell className={cn({ 'font-bold text-red-600': isPreferredOfferNearTermEnd(takerMarket) })}>
                        <div className="flex items-center gap-1">
                          {takerMarket.disableAfterMarketClearsDate &&
                            trimDateTimestamp(takerMarket.disableAfterMarketClearsDate)}
                          <RenewalRequestIcon takerMarket={takerMarket} iconStyles="h-5 w-5" fill={colors.red[600]} />
                        </div>
                      </TableCell>
                      <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                        {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                      </TableCell>
                      <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                        {takerMarket.marketIsEnabled &&
                          takerMarket.marketPayDate &&
                          trimDateTimestamp(takerMarket.marketPayDate)}
                      </TableCell>
                      {isMarketRateColumnShown && (
                        <TableCell
                          data-testid="takermarkettable.preferred.marketRate"
                          className={cn('m-auto max-w-lg space-y-2 text-right', { 'blur-sm': listeningForMarketStat })}
                        >
                          {rateName ? (
                            <div className="flex items-center justify-end">
                              <button
                                className="-mr-2 flex items-center gap-2 rounded p-2 uppercase hover:bg-gray-100"
                                type="button"
                                onClick={() => openVariableRateModal(takerMarket)}
                                aria-label="Market Rate Info"
                              >
                                {rateLabel}
                                <QuestionCircleIcon fill={colors.gray[800]} />
                              </button>
                            </div>
                          ) : (
                            t('core.na')
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        data-testid="takermarkettable.preferred.totalrate"
                        className={cn('text-right', { 'blur-sm': listeningForMarketStat })}
                      >
                        <div className="flex items-center justify-end">{formattedEstimatedRate}</div>
                      </TableCell>
                      {takerMarket.marketIsEnabled && !takerMarket.offerConfig.isEnabled ? (
                        <TableCell colSpan={3} className="text-center text-sm font-medium text-red-500">
                          {t('taker.marketsGridNotParticipating')}
                        </TableCell>
                      ) : (
                        <>
                          <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                            {asCurrency(takerMarket.acceptedInvoiceAmount, takerMarket.currency)}
                          </TableCell>
                          <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                            {asCurrency(takerMarket.notAcceptedInvoiceAmount, takerMarket.currency)}
                          </TableCell>
                          <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                            {asCurrency(takerMarket.acceptedEarn, takerMarket.currency)}
                          </TableCell>
                        </>
                      )}
                      <TableCell className={cn('text-right', { 'blur-sm': listeningForMarketStat })}>
                        {asNumber({ value: takerMarket.eligibleDpeWeightedAvg, decimals: 0 })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <>
          <Modal open={showPreferredRequestModal} onClose={() => setShowPreferredRequestModal(false)} size="sm">
            <ModalTitleInfo>{t('preferredMarketing.modal.requestSubmitted')}</ModalTitleInfo>
            <ModalContent center>
              <CircleCheckIcon className="mx-auto mb-3 h-[70px] w-auto" />
              <p>{t('preferredMarketing.modal.requestSubmitted.description')}</p>
            </ModalContent>
            <ModalActions>
              <Button variant="secondary" onClick={() => setShowPreferredRequestModal(false)}>
                {t('core.close')}
              </Button>
            </ModalActions>
          </Modal>

          <div className="p-4">
            <div className="mx-auto max-w-[224px]">
              <PreferredLogo />
            </div>
            <div className="mx-auto max-w-2xl text-center">
              <h4>{t('preferredMarketing.table.title')}</h4>
              <p>{t('preferredMarketing.table.description')}</p>
              <p className="my-6 text-left">{t('preferredMarketing.table.benefits.header')}</p>
              <ul className="ml-7 list-disc text-left">
                <li>
                  <span className="font-semibold">{t('preferredMarketing.table.benefits.peaceOfMind')} </span>
                  {t('preferredMarketing.table.benefits.peaceOfMind.description')}
                </li>
                <li>
                  <span className="font-semibold">{t('preferredMarketing.table.benefits.cashFlow')} </span>
                  {t('preferredMarketing.table.benefits.cashFlow.description')}
                </li>
                <li>
                  <span className="font-semibold">{t('preferredMarketing.table.benefits.simplicity')} </span>
                  {t('preferredMarketing.table.benefits.simplicity.description')}
                </li>
              </ul>
              <Button
                size="md"
                variant="secondary"
                className="mx-auto my-6 w-full md:w-fit"
                onClick={sendPreferredInfoRequest}
                loading={isLoadingSubmitInformationRequestPreferred}
                disabled={isLoadingSubmitInformationRequestPreferred || preferredInfoRequestSubmitted}
              >
                {t('preferredMarketing.table.requestInformation')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PreferredRateMarketTable;
