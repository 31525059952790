import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Paper,
  Popover,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { EllipsisVerticalIcon, InfoCircleIcon, TrashCanIcon } from '@c2fo/liquidity/icons';
import invoices from '@/assets/invoices.svg?url';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import { DeleteRecurringRuleArgument } from '@/data/useDeleteRecurringRule';
import useRecurringRules, { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import DeleteAllRulesModal from './components/DeleteAllRulesModal';
import DeleteRuleCriteriaModal from './components/DeleteRuleCriteriaModal';
import DeleteRuleModal from './components/DeleteRuleModal';
import Pagination, { getStartEnd } from './components/Pagination';
import RecurringRulesForm, { RecurringRulesFormProps } from './RecurringRulesForm';
import SetRuleCriteria from './SetRuleCriteria';
import getIsBeta from './utils/getIsBeta';
import sortRulesTable, { Sort, SortKey } from './utils/sortRulesTable';

const DivisionTitle = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const takerMarketName = getTakerMarketName(takerMarket);
  const buyerDivisionName = getTakerMarketDivisionTitle(takerMarket)?.content;
  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

  return (
    <div className="flex flex-col">
      <EllipsisTooltip
        content={takerMarketName}
        trigger={
          <Link
            className="font-bold"
            to={`/supplier/markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
          >
            {takerMarketName}
          </Link>
        }
      />
      <div className="text-sm text-text-primary">{takerMarket.currency}</div>
      <EllipsisTooltip content={buyerDivisionName} trigger={<span className="text-sm">{buyerDivisionName}</span>} />
      {isPreferred && (
        <div className="flex gap-1">
          <Popover>
            <Popover.Trigger>
              <InfoCircleIcon className="h-4 w-4" fill={colors.gray[600]} />
            </Popover.Trigger>
            <Popover.Content arrow side="bottom">
              {t('recurringRules.preferredOfferLocked')}
            </Popover.Content>
          </Popover>
          <span className="text-wrap text-sm italic text-text-secondary">{t('core.preferredOffer')}</span>
        </div>
      )}
    </div>
  );
};

const RulesTable = ({ showInformationRequest }: { showInformationRequest: () => void }) => {
  const isBeta = getIsBeta();
  const { t } = useTranslation();
  const { track } = useReporting();
  const recurringRules = useRecurringRules();
  const { asCurrency } = useLocaleFormat();
  const ref = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const sortedRules = sortRulesTable(recurringRules, sort);
  const totalCount = recurringRules.length;
  const { start, end } = getStartEnd({ limit, page, totalCount });
  const [isScrollable, setIsScrollable] = useState(false);
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);
  // create/edit rule
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [ruleModalProps, setRuleModalProps] = useState<
    Pick<RecurringRulesFormProps, 'defaultValues' | 'mode' | 'takerMarkets' | 'type'>
  >({
    takerMarkets: [],
  });
  // delete all rules
  const [openDeleteAllRulesModal, setOpenDeleteAllRulesModal] = useState(false);
  // delete rule
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState<DeleteRecurringRuleArgument | null>(null);
  // delete rule criteria
  const [openDeleteRuleCriteriaModal, setOpenDeleteRuleCriteriaModal] = useState(false);
  const [ruleCriteriaToDelete, setRuleCriteriaToDelete] = useState<{
    rule: RecurringRule;
    category: RecurringRuleCategory;
  } | null>(null);

  const onPageSizeChange = (limit: number) => {
    setLimit(limit);
    setPage(1);
  };

  const onSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'takerMarketName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const findScrollable = () => {
    const scrollable = ref.current && ref.current.offsetWidth < ref.current.scrollWidth;
    setIsScrollable(!!scrollable);
  };

  useEffect(() => {
    findScrollable();
    window.addEventListener('resize', findScrollable);
  });

  const tableHeadColumns: { accessorKey?: SortKey; translation: string; className: string }[] = [
    {
      accessorKey: 'takerMarketName',
      translation: t('core.division'),
      className: cn('sticky left-0 top-0 z-[5] w-44 bg-white sm:w-60', { 'drop-shadow-lg': isScrollable }),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('taker.dictionary.availableAR.label'),
      className: 'w-36 pr-4',
    },
    {
      translation: t('core.daysPaidEarly'),
      className: 'w-48 text-center',
    },
    {
      translation: t('recurringRules.dueDate.category.label'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.invoiceAmount'),
      className: 'w-48 text-center',
    },
    {
      translation: t('core.invoiceId'),
      className: 'w-48 text-center',
    },
    {
      translation: '',
      className: 'w-20 text-center',
    },
  ];

  const handleOpenRuleModal = ({
    defaultValues,
    takerMarkets,
    type,
  }: Pick<RecurringRulesFormProps, 'defaultValues' | 'takerMarkets' | 'type'>) => {
    setRuleModalProps({
      defaultValues,
      takerMarkets,
      type,
      ...((defaultValues?.criteria?.length ?? 0) > 0 && { mode: 'edit' }),
    });
    setOpenRuleModal(true);
    track('recurring-rules::opened', {
      referrer: 'rules-manager',
      source: 'table',
    });
  };

  const handleCloseRuleModal = () => {
    setOpenRuleModal(false);
    track('recurring-rules::closed');
  };

  const handleOpenDeleteAllRulesModal = (rule: RecurringRule) => {
    setOpenDeleteRuleModal(true);
    setRuleToDelete({
      id: rule.id,
      makerOrganizationUuid: rule.makerOrganizationUuid,
      marketId: rule.marketId,
      takerId: rule.takerId,
    });
  };

  const handleOpenDeleteRuleModal = (rule: RecurringRule, category: RecurringRuleCategory) => {
    setOpenDeleteRuleCriteriaModal(true);
    setRuleCriteriaToDelete({ rule, category });
  };

  return (
    <div>
      <RecurringRulesForm isForSingleDivision onClose={handleCloseRuleModal} open={openRuleModal} {...ruleModalProps} />
      <DeleteAllRulesModal
        onClose={() => {
          setOpenDeleteAllRulesModal(false);
          track('recurring-rules::bulk-delete::all', {
            referrer: 'rules-manager',
          });
        }}
        open={openDeleteAllRulesModal}
      />
      <DeleteRuleCriteriaModal
        onClose={() => {
          setOpenDeleteRuleCriteriaModal(false);
          setRuleCriteriaToDelete(null);
        }}
        open={openDeleteRuleCriteriaModal}
        ruleMeta={ruleCriteriaToDelete!}
      />
      <DeleteRuleModal
        onClose={() => {
          setOpenDeleteRuleModal(false);
          setRuleToDelete(null);
          track('recurring-rules::bulk-delete::division', {
            makerOrganizationUuid: ruleToDelete!.makerOrganizationUuid,
            referrer: 'rules-manager',
            takerMarkets: [{ marketId: ruleToDelete!.marketId, takerId: ruleToDelete!.takerId }],
          });
        }}
        open={openDeleteRuleModal}
        rule={ruleToDelete!}
      />
      {isBeta && (
        <Paper className="mb-11 px-8 py-6" elevation={2}>
          <div className="flex flex-col items-center gap-7 md:flex-row">
            <div className="shrink-0">
              <img src={invoices} alt={t('recurringRules.createRecurringRule')} className="mx-auto h-20 w-20" />
            </div>
            <div>
              <div className="mb-2 text-xl font-medium">{t('recurringRules.noActiveRulesTitleBeta')}</div>
              <div>{t('recurringRules.noActiveRulesMessageBeta')}</div>
            </div>
            <div className="w-full shrink-0 md:w-fit">
              <Button onClick={showInformationRequest} variant="secondary">
                {t('recurringRules.noActiveRulesCtaBeta')}
              </Button>
            </div>
          </div>
        </Paper>
      )}
      <h2 className="m-0 pb-8 text-2xl">{t('recurringRules.activeRules')}</h2>
      <Paper className="px-8 pt-8" elevation={2}>
        <div className="flex justify-between rounded-t border border-stroke bg-canvas px-6 py-4">
          <div></div>
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <IconButton icon={EllipsisVerticalIcon} name={t('recurringRules.menu')} size="sm" variant="ancillary" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" avoidCollisions={false} collisionPadding={24}>
                <DropdownMenuItem
                  disabled={!canEditRecurringRules}
                  {...(canEditRecurringRules && { onClick: () => setOpenDeleteAllRulesModal(true) })}
                >
                  {t('recurringRules.deleteAllRules')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="w-full overflow-auto">
          <div ref={ref} className="overflow-auto rounded-b border border-t-0 border-stroke">
            <Table className="table-fixed">
              <TableHeader>
                <TableRow>
                  {tableHeadColumns.map(({ accessorKey, translation, className }) => (
                    <TableHead
                      key={translation}
                      className={cn('px-2 py-4', className)}
                      {...(!!accessorKey && { onClick: () => onSort(accessorKey) })}
                    >
                      {!!accessorKey ? (
                        <TableSortArrow
                          accessorKey={accessorKey}
                          sort={sort}
                          {...(['eligibleInvoiceAmount'].includes(accessorKey) && { textRight: true })}
                        >
                          {translation}
                        </TableSortArrow>
                      ) : (
                        translation
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedRules.slice(start - 1, end).map((takerMarketWithRule) => {
                  const { rule, takerMarket } = takerMarketWithRule;
                  const { canEditRecurringRules } = getRestrictions([takerMarket]);

                  return (
                    <TableRow key={rule.id}>
                      <TableCell className={cn('sticky left-0 z-[5] bg-white', { 'drop-shadow-lg': isScrollable })}>
                        <DivisionTitle takerMarket={takerMarket} />
                      </TableCell>
                      <TableCell className="truncate border-r pr-4 text-end">
                        {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="dpe"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'dpe')}
                        />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="dueDate"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'dueDate')}
                        />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="amount"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'amount')}
                        />
                      </TableCell>
                      <TableCell className="border-r">
                        <SetRuleCriteria
                          ruleCategory="invoiceId"
                          takerMarketWithRule={takerMarketWithRule}
                          setModalProps={handleOpenRuleModal}
                          onDelete={() => handleOpenDeleteRuleModal(rule, 'invoiceId')}
                        />
                      </TableCell>
                      <TableCell className="px-0 last-of-type:pr-0">
                        <div className="flex items-center justify-center">
                          <IconButton
                            name={t('recurringRules.removeRule')}
                            variant="destructive"
                            size="md"
                            inverse
                            icon={TrashCanIcon}
                            onClick={() => handleOpenDeleteAllRulesModal(rule)}
                            disabled={!canEditRecurringRules}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
        <div className="flex justify-center p-4">
          <Pagination
            limit={limit}
            onPageChange={setPage}
            onPageSizeChange={onPageSizeChange}
            page={page}
            totalCount={totalCount}
          />
        </div>
      </Paper>
    </div>
  );
};

export default RulesTable;
