import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, cn } from '@c2fo/liquidity';
import Modal, { ModalProps } from '@/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/components/OfferConfigDetails';
import { OfferDuration } from '@/components/OfferDuration';
import { useBulkExpirationDateUpdate } from '@/data/useBulkExpirationDateUpdate';
import { TakerMarket } from '@/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useRestrictions from '@/utils/useRestrictions';

interface SetBulkExpirationDateForm {
  expirationDate: string | null;
}

interface SetBulkExpirationDateProps {
  open: boolean;
  onClose: () => void;
  takerMarkets: TakerMarket[];
}

export const SetBulkExpirationDate = ({ open, onClose, takerMarkets }: SetBulkExpirationDateProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions(takerMarkets);
  const [step, setStep] = useState<number>(1);
  const { applyBulkExpirationDateOperation } = useBulkExpirationDateUpdate();
  const [takerMarketsNotUpdated, setTakerMarketsNotUpdated] = useState<TakerMarket[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues: SetBulkExpirationDateForm = { expirationDate: null };

  const { handleSubmit, reset, watch, setValue } = useForm<SetBulkExpirationDateForm>({ defaultValues });

  useEffect(() => {
    if (open) {
      reset(defaultValues);
      setStep(1);
      setTakerMarketsNotUpdated([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const takerMarketsWithEnabledOffers = useMemo(() => {
    return takerMarkets.filter((takerMarket) => takerMarket?.offerConfig?.isEnabled);
  }, [takerMarkets]);

  const onSubmit: SubmitHandler<SetBulkExpirationDateForm> = async (data) => {
    if (!canEditOffers) {
      return;
    }

    if (step === 1) {
      setStep(2);
    } else {
      setLoading(true);
      track('bulk-expiration-date-update::submitted', {
        takerMarketCount: takerMarketsWithEnabledOffers.length,
        takerMarkets: takerMarketsWithEnabledOffers.map((takerMarket) => ({
          marketUuid: takerMarket.marketUuid,
          takerDivisionUuid: takerMarket.takerDivisionUuid,
          makerOrganizationUuid: takerMarket.makerOrganizationUuid,
        })),
        expirationDate: data.expirationDate,
      });
      const result = await applyBulkExpirationDateOperation({
        takerMarkets: takerMarketsWithEnabledOffers,
        expirationDate: data.expirationDate,
      });

      if (result.takerMarketsNotUpdated.length > 0) {
        track('bulk-expiration-date-update::failed', {
          failedTakerMarketCount: result.takerMarketsNotUpdated.length,
          failedTakerMarkets: result.takerMarketsNotUpdated.map((takerMarket) => ({
            marketUuid: takerMarket.marketUuid,
            takerDivisionUuid: takerMarket.takerDivisionUuid,
            makerOrganizationUuid: takerMarket.makerOrganizationUuid,
          })),
        });
        setTakerMarketsNotUpdated(result.takerMarketsNotUpdated);
      } else {
        onClose();
      }

      setLoading(false);
    }
  };

  const expirationDateFormValue = watch('expirationDate');

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [];

    if (step === 2) {
      items.push({
        label: t('taker.dictionary.expirationDate.label'),
        value: expirationDateFormValue ? trimDateTimestamp(expirationDateFormValue) : t('core.na'),
      });
    }

    return items;
  }, [expirationDateFormValue, step, t]);

  const isWarningState = takerMarketsNotUpdated.length > 0;

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          onOk: handleSubmit(onSubmit),
        }
      : {
          cancelText: t('core.back'),
          okButtonVariant: isWarningState ? 'primary' : 'cta',
          okText: isWarningState ? t('core.close') : t('core.submit'),
          onCancel: isWarningState ? undefined : () => setStep(1),
          onOk: isWarningState ? onClose : handleSubmit(onSubmit),
          okButtonDisabled: loading,
          loading: loading,
        };

  const subTitle = useMemo(() => {
    if (step === 2 && expirationDateFormValue !== null) {
      return t('expirationDate.bulkUpdate.subTitle.apply', { count: takerMarketsWithEnabledOffers.length });
    }

    if (step === 2 && expirationDateFormValue === null) {
      return t('expirationDate.bulkUpdate.subTitle.remove', { count: takerMarketsWithEnabledOffers.length });
    }

    return t('expirationDate.bulkUpdate.subTitle', { count: takerMarketsWithEnabledOffers.length });
  }, [expirationDateFormValue, step, t, takerMarketsWithEnabledOffers.length]);

  return (
    <Modal
      fullWidthButtons
      loading={false}
      onClose={onClose}
      open={open}
      subTitle={subTitle}
      title={t('expirationDate.bulkUpdate.title')}
      {...modalConfig}
    >
      <div className="relative space-y-6 bg-white text-text-primary">
        <OfferConfigDetails items={offerConfigItems} />

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* offer form */}
          <div
            className={cn('space-y-6', {
              hidden: step === 2,
            })}
          >
            <OfferDuration
              isAlwaysOpen
              isBgTransparent
              expireOn={expirationDateFormValue ?? null}
              setExpireOn={(isoDateString) => {
                setValue('expirationDate', isoDateString);
              }}
            />
          </div>
          {/* understand info */}
          <div className={cn('space-y-6', { hidden: step === 1 })}>
            <ul className="ml-4 list-disc space-y-2">
              {expirationDateFormValue !== null ? (
                <li>{t('expirationDate.bulkUpdate.review.participation.apply')}</li>
              ) : (
                <li>{t('expirationDate.bulkUpdate.review.participation.remove')}</li>
              )}
              <li>{t('allOfferDialogs.description2')}</li>
              <li>{t('expirationDate.bulkUpdate.review.applicationTime')}</li>
              <li>{t('offerSubmitDialog.disclaimer')}</li>
            </ul>

            {isWarningState && (
              <Alert
                full
                type="warning"
                title={t('expirationDate.bulkUpdate.snackbar.failure')}
                description={
                  <div className="space-y-4">
                    {takerMarketsNotUpdated.map((failedTakerMarket) => (
                      <div key={failedTakerMarket.id}>
                        <p>{getTakerMarketName(failedTakerMarket)}</p>
                        <p>{getTakerMarketDivisionTitle(failedTakerMarket)?.content}</p>
                      </div>
                    ))}
                    <p>{t('expirationDate.bulkUpdate.help')}</p>
                  </div>
                }
              />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};
