import { useMutation } from '@tanstack/react-query';
import useRefetchAndSubscribeForRules from '@/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import { mapRuleArgumentToRuleArray } from '@/features/recurringRules/utils/utils';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import { RecurringRuleArgument } from './useCreateRecurringRule';
import { formatAsRecurringRule, RecurringRule } from './useRecurringRules';

export interface UpdateRecurringRuleArgument extends Omit<RecurringRuleArgument, 'takersMarkets'> {
  id: string;
  existingRules: RecurringRule[];
  marketId: number;
  marketUuid: string;
  takerId: number;
  takerUuid: string;
}

export interface UpdateRecurringRuleMap {
  gteDpe?: {
    value: number | null;
  };
  lteDpe?: {
    value: number | null;
  };
  fromDueDate?: {
    value: string | null;
  };
  toDueDate?: {
    value: string | null;
  };
  gteInvoiceAmount?: {
    value: number | null;
  };
  lteInvoiceAmount?: {
    value: number | null;
  };
  excludedVoucherIds?: {
    value: string[] | null;
  };
}

const useUpdateRecurringRule = () => {
  const { track } = useReporting();
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['updateRecurringRule'],
    mutationFn: (rule: UpdateRecurringRuleArgument) => {
      const { id, makerOrganizationUuid, ...ruleArgs } = rule;

      /**
       * formats provided argument for backend use
       * includes formatting for null values to ensure any removed criteria are updated correctly
       */
      const args = {
        ...(ruleArgs.gteDpe || ruleArgs.lteDpe
          ? {
              gteDpe: {
                value: ruleArgs.gteDpe ?? null,
              },
              lteDpe: {
                value: ruleArgs.lteDpe ?? null,
              },
            }
          : {}),
        ...(ruleArgs.fromDueDate || ruleArgs.toDueDate
          ? {
              fromDueDate: {
                value: ruleArgs.fromDueDate ?? null,
              },
              toDueDate: {
                value: ruleArgs.toDueDate ?? null,
              },
            }
          : {}),
        ...(ruleArgs.gteInvoiceAmount || ruleArgs.lteInvoiceAmount
          ? {
              gteInvoiceAmount: {
                value: ruleArgs.gteInvoiceAmount ?? null,
              },
              lteInvoiceAmount: {
                value: ruleArgs.lteInvoiceAmount ?? null,
              },
            }
          : {}),
        ...(ruleArgs.excludedVoucherIds
          ? {
              excludedVoucherIds: {
                value: ruleArgs.excludedVoucherIds ?? null,
              },
            }
          : {}),
      } satisfies UpdateRecurringRuleMap;

      return apiClient
        .patch(`api/eligibility-service/maker-organizations/${makerOrganizationUuid}/invoice-exclusion-rules/${id}`, {
          json: args,
        })
        .json();
    },
    onSuccess: (_, variables) => {
      const { existingRules, makerOrganizationUuid, marketId, marketUuid, takerId } = variables;

      // for tracking, updating a rule with new criteria is considered a rule create
      const isUpdatedRule = Object.keys(existingRules.find((rule) => rule.id === variables.id) ?? {})
        .filter((key) => ['amount', 'dpe', 'dueDate', 'excludedVoucherIds'].includes(key))
        .some((key) => Object.keys(formatAsRecurringRule(variables) ?? {}).includes(key));

      if (isUpdatedRule) {
        track('recurring-rules::updated', {
          makerOrganizationUuid,
          rules: mapRuleArgumentToRuleArray(variables),
          takerMarkets: [{ marketId, takerId }],
        });
      } else {
        track('recurring-rules::created', {
          makerOrganizationUuid,
          rules: mapRuleArgumentToRuleArray(variables),
          takerMarkets: [{ marketId, takerId }],
        });
      }

      refetchAndSubscribeRules({ makerOrganizationUuid, takerMarkets: [{ marketUuid, takerId }] });
    },
  });
};

export default useUpdateRecurringRule;
