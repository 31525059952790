import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, cn, Modal, ModalActions, ModalContent, ModalTitleInfo, ModalTitleInverse } from '@c2fo/liquidity';
import QueryBoundaries from '@/components/QueryBoundaries';
import useCreateRecurringRule, { RecurringRuleArgument } from '@/data/useCreateRecurringRule';
import useExchangeRates from '@/data/useExchangeRates';
import { RecurringRuleCategory, RecurringRuleCriteria } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import useUpdateOffer, { UpdateOfferVariables } from '@/data/useUpdateOffer';
import useUpdateRecurringRule, { UpdateRecurringRuleArgument } from '@/data/useUpdateRecurringRule';
import batchPromiseAllSettled from '@/utils/batchPromiseAllSettled';
import convertCurrency from '@/utils/convertCurrency';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useRestrictions from '@/utils/useRestrictions';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import CategorySelect from './components/CategorySelect';
import CriteriaSelect from './components/CriteriaSelect';
import DivisionSelectTable from './components/DivisionSelectTable';
import ExistingRulesAlert from './components/ExistingRulesAlert';
import ReviewRule from './components/ReviewRule';
import defaultRuleValues from './utils/defaultRuleValues';
import groupRulesByMakerOrg from './utils/groupRulesByMakerOrg';
import { getInitialState, recurringRulesFormReducer } from './utils/recurringRulesFormReducer';
import useGetRecurringRulesForTakerMarkets from './utils/useGetRecurringRulesForTakerMarkets';
import useRuleFormContent from './utils/useRuleFormContent';
import useValidateRuleForm, { RecurringRuleFormError } from './utils/useValidateRuleForm';
import { mapFormCriteriaToRuleRecord, mapRuleRecordToSubmit } from './utils/utils';

export interface RecurringRulesFormProps {
  /* callback to close the modal */
  onClose: () => void;
  /* whether or not the modal is open */
  open: boolean;
  /* a list of taker markets to render within the division select table */
  takerMarkets: TakerMarket[];
  /* default values to pre-populate the form with */
  defaultValues?: Partial<RecurringRuleFormValues>;
  /*
   * whether or not the form should adapt config for a single division
   * will hide the division select table and display information
   * within the category select for a single division
   * defaults to false
   */
  isForSingleDivision?: boolean;
  /*
   * the mode of the form
   * create will display as creating a rule
   * edit will display as editing a rule
   * readOnly prevents create/edit and displays list of the rules
   * defaults to create
   */
  mode?: 'create' | 'edit' | 'readOnly';
  /* the type of taker market group */
  type?: TakerMarketGroupType;
  /* Set while api is calling to prevent closing modal while loading */
  setDisableModalClose: (value: boolean) => void;
}

export interface RecurringRuleFormCriteriaValue {
  type: RecurringRuleCriteria;
  value: string;
}

export interface RecurringRuleFormValues {
  category: RecurringRuleCategory;
  criteria: RecurringRuleFormCriteriaValue[];
  takerMarkets: TakerMarket[];
}

/**
 * initial form values default to days paid early
 * if a category is provided, the default values will be set to the category
 */
const getInitialFormValues = (defaultValues?: Partial<RecurringRuleFormValues>) => {
  return {
    defaultValues: {
      ...(defaultValues?.category ? defaultRuleValues[defaultValues?.category] : defaultRuleValues['dpe']),
      ...defaultValues,
    },
  };
};

const RecurringRulesFormComponent = ({
  defaultValues,
  isForSingleDivision = false,
  mode = 'create',
  onClose,
  takerMarkets,
  type,
  setDisableModalClose,
}: RecurringRulesFormProps) => {
  const { t } = useTranslation();
  const selectedCurrency = useSelectedCurrency();
  const { data: exchangeRates = {} } = useExchangeRates();
  const [errors, setErrors] = useState<RecurringRuleFormError[]>([]);
  const { getRuleFormContent } = useRuleFormContent();
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<{
    showError: boolean;
    takerMarkets: { takerId: number; marketId: number }[];
  }>({ showError: false, takerMarkets: [] });
  const { mutateAsync: toggleExcludeFutureInvoices } = useUpdateOffer();
  const { mutateAsync: createRecurringRule } = useCreateRecurringRule();
  const { mutateAsync: updateRecurringRule } = useUpdateRecurringRule();
  const initialState = getInitialState({ category: defaultValues?.category, mode });
  const [state, dispatch] = useReducer(recurringRulesFormReducer, initialState);
  const initialFormValues = getInitialFormValues(defaultValues);
  const methods = useForm<RecurringRuleFormValues>(initialFormValues);
  const category = methods.watch('category');
  const selectedTakerMarkets = methods.watch('takerMarkets');
  const { description } = getRuleFormContent(category);
  const { validateRuleForm } = useValidateRuleForm();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);
  const toCurrency = isForSingleDivision ? selectedTakerMarkets?.[0]?.currency ?? selectedCurrency : selectedCurrency;
  const isReadOnlyMode = mode === 'readOnly' || !canEditRecurringRules;

  const onTakerMarketTableSelect = (takerMarkets: TakerMarket[]) => {
    methods.setValue('takerMarkets', takerMarkets);
    setErrors(errors.filter((error) => error.type !== 'takerMarkets'));
  };

  const handleClose = () => {
    setSubmitErrors({ showError: false, takerMarkets: [] });
    onClose();
  };

  const onSubmit: SubmitHandler<RecurringRuleFormValues> = async (data) => {
    setLoading(true);
    setDisableModalClose(true);

    const existingRules = getRecurringRulesForTakerMarkets(data.takerMarkets);

    // map existing rules to update
    const updateRuleArguments: UpdateRecurringRuleArgument[] = existingRules.map((rule) => {
      const ruleRecord = mapFormCriteriaToRuleRecord(data.criteria);
      const ruleArgument = mapRuleRecordToSubmit(ruleRecord);

      return {
        id: rule.id,
        existingRules,
        makerOrganizationUuid: rule.makerOrganizationUuid,
        marketId: rule.marketId,
        marketUuid: rule.marketUuid,
        takerId: rule.takerId,
        takerUuid: rule.takerUuid,
        ...ruleArgument,
        ...(ruleArgument.gteInvoiceAmount && {
          gteInvoiceAmount: Number(
            convertCurrency({
              amount: Number(ruleArgument.gteInvoiceAmount),
              exchangeRates,
              from: toCurrency,
              to: rule.currency,
            }).toFixed(2)
          ),
        }),
        ...(ruleArgument.lteInvoiceAmount && {
          lteInvoiceAmount: Number(
            convertCurrency({
              amount: Number(ruleArgument.lteInvoiceAmount),
              exchangeRates,
              from: toCurrency,
              to: rule.currency,
            }).toFixed(2)
          ),
        }),
      };
    });

    // filter taker markets with existing rules from rules to create
    const rulesToCreate = {
      ...data,
      takerMarkets: data.takerMarkets.filter(
        (takerMarket) =>
          !existingRules.find(
            (rule) =>
              rule?.makerOrganizationUuid === takerMarket.makerOrganizationUuid &&
              rule.takerId === takerMarket.takerDivisionId &&
              rule.marketId === takerMarket.legacyMarketId
          )
      ),
    };

    // map rules to create by maker org
    const mappedRulesToMakerOrg = groupRulesByMakerOrg(rulesToCreate);
    const createRuleArguments: {
      createRule: RecurringRuleArgument;
      toggleExcludeFutureInvoicesOff: UpdateOfferVariables[];
    }[] = Object.entries(mappedRulesToMakerOrg).flatMap(([makerOrganizationUuid, ruleRecord]) => {
      return Object.entries(ruleRecord).flatMap(([currency, rule]) => {
        const { takerMarkets: takersMarkets, ...restRuleRecord } = rule;
        const ruleArgument = mapRuleRecordToSubmit(restRuleRecord);

        return {
          createRule: {
            makerOrganizationUuid,
            takersMarkets: takersMarkets.map((tm) => ({
              marketId: tm.marketId,
              marketUuid: tm.marketUuid,
              takerId: tm.takerId,
              takerUuid: tm.takerUuid,
            })),
            ...ruleArgument,
            ...(restRuleRecord.gteInvoiceAmount && {
              gteInvoiceAmount: Number(
                convertCurrency({
                  amount: Number(restRuleRecord.gteInvoiceAmount),
                  exchangeRates,
                  from: toCurrency,
                  to: currency,
                }).toFixed(2)
              ),
            }),
            ...(restRuleRecord.lteInvoiceAmount && {
              lteInvoiceAmount: Number(
                convertCurrency({
                  amount: Number(restRuleRecord.lteInvoiceAmount),
                  exchangeRates,
                  from: toCurrency,
                  to: currency,
                }).toFixed(2)
              ),
            }),
          },
          toggleExcludeFutureInvoicesOff: takersMarkets.reduce((updateOfferArgs, takerMarket) => {
            if (
              takerMarket.offerConfig &&
              takerMarket.offerConfig.exclusionSettings &&
              takerMarket.offerConfig.exclusionSettings.excludeNewInvoices === true
            ) {
              updateOfferArgs.push({
                marketId: takerMarket.offerConfig.marketId,
                marketUuid: takerMarket.offerConfig.marketUuid,
                offerConfig: {
                  ...takerMarket.offerConfig,
                  /**
                   * for benchmark submits, set maxApr to 99
                   * this prevents updating rate to anything other than 99%
                   */
                  ...(getMarketType(takerMarket) === 'BENCHMARK' ? { maxApr: 99 } : {}),
                  exclusionSettings: {
                    ...takerMarket.offerConfig.exclusionSettings,
                    excludeNewInvoices: false,
                  },
                },
                takerId: takerMarket.offerConfig.divisionId,
              } satisfies UpdateOfferVariables);
            }

            return updateOfferArgs;
          }, [] as UpdateOfferVariables[]),
        };
      });
    });

    // await all promises to settle
    const [toggleExcludeFutureInvoicesResult, createRecurringRuleResult, updateRecurringRuleResult] =
      await Promise.allSettled([
        await batchPromiseAllSettled({
          fn: async (argument) => {
            await toggleExcludeFutureInvoices(argument);
          },
          items: createRuleArguments.flatMap((argument) => argument.toggleExcludeFutureInvoicesOff),
          size: 5,
        }),
        await batchPromiseAllSettled({
          fn: async (argument) => {
            await createRecurringRule(argument);
          },
          items: createRuleArguments.flatMap((argument) => argument.createRule),
          size: 5,
        }),
        await batchPromiseAllSettled({
          fn: async (argument) => {
            await updateRecurringRule(argument);
          },
          items: updateRuleArguments,
          size: 5,
        }),
      ]);

    // check if any of the promises were rejected or returned errors
    if (
      toggleExcludeFutureInvoicesResult.status === 'rejected' ||
      createRecurringRuleResult.status === 'rejected' ||
      updateRecurringRuleResult.status === 'rejected' ||
      (toggleExcludeFutureInvoicesResult.status === 'fulfilled' &&
        toggleExcludeFutureInvoicesResult.value.errors.length > 0) ||
      (createRecurringRuleResult.status === 'fulfilled' && createRecurringRuleResult.value.errors.length > 0) ||
      (updateRecurringRuleResult.status === 'fulfilled' && updateRecurringRuleResult.value.errors.length > 0)
    ) {
      const takerMarketsInError: { takerId: number; marketId: number }[] = [];

      if (
        toggleExcludeFutureInvoicesResult.status === 'fulfilled' &&
        toggleExcludeFutureInvoicesResult.value.errors.length > 0
      ) {
        toggleExcludeFutureInvoicesResult.value.errors.forEach(({ item }) => {
          takerMarketsInError.push({ takerId: item.takerId, marketId: Number(item.marketId) });
        });
      }

      if (createRecurringRuleResult.status === 'fulfilled' && createRecurringRuleResult.value.errors.length > 0) {
        createRecurringRuleResult.value.errors.forEach(({ item }) => {
          item.takersMarkets.forEach((takerMarket) => {
            takerMarketsInError.push({ takerId: takerMarket.takerId, marketId: takerMarket.marketId });
          });
        });
      }

      if (updateRecurringRuleResult.status === 'fulfilled' && updateRecurringRuleResult.value.errors.length > 0) {
        updateRecurringRuleResult.value.errors.forEach(({ item }) => {
          takerMarketsInError.push({ takerId: item.takerId, marketId: item.marketId });
        });
      }

      setSubmitErrors({ showError: true, takerMarkets: takerMarketsInError });
      setLoading(false);
      setDisableModalClose(false);
    } else {
      setSubmitErrors({ showError: false, takerMarkets: [] });
      setLoading(false);
      setDisableModalClose(false);
      // send to create another if there are no errors
      dispatch({ type: 'setDisplay', payload: { display: 'createAnother' } });
    }
  };

  const handleStepBack = () => {
    dispatch({ type: 'stepBack' });
    setErrors([]);
    setSubmitErrors({ showError: false, takerMarkets: [] });
  };

  const handleStepForward = () => {
    if (state.currentStep === 'criteria') {
      // validate the form before advancing
      const [criteria, takerMarkets] = methods.getValues(['criteria', 'takerMarkets']);
      const errors = validateRuleForm({ criteria, takerMarkets });

      if (errors.length > 0) {
        return setErrors(errors);
      }

      // if the form is valid, advance to the next step
      dispatch({ type: 'stepForward' });
    }
  };

  // set width of modal content based on current step and display
  const modalContentClassName = cn(
    'w-full min-w-full pt-3 sm:w-[568px]',
    { 'w-full sm:w-full lg:w-[704px]': state.currentStep === 'summary' },
    { 'w-full sm:w-full lg:w-[880px]': state.currentStep === 'criteria' && !isForSingleDivision },
    {
      'w-full sm:w-[592px] lg:w-[592px]': state.currentStep === 'category' || state.display === 'createAnother',
    }
  );

  return (
    <>
      {state.currentStep === 'category' || state.display === 'createAnother' ? (
        <ModalTitleInfo>
          {isReadOnlyMode || state.currentStep === 'category'
            ? t('recurringRules.title')
            : state.display === 'createAnother'
            ? t(`recurringRules.ruleApplied`)
            : t('recurringRules.createRecurringRule')}
        </ModalTitleInfo>
      ) : (
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>
              {state.isEditMode ? t('recurringRules.editRecurringRule') : t('recurringRules.createRecurringRule')}
            </span>
            {isForSingleDivision && selectedTakerMarkets[0] && (
              <span className="text-sm font-normal text-text-secondary">
                {getTakerMarketName(selectedTakerMarkets[0])}
              </span>
            )}
          </span>
        </ModalTitleInverse>
      )}
      {isReadOnlyMode ? (
        <ModalContent className={modalContentClassName}>
          <CategorySelect
            advanceStep={() => null}
            setEditMode={() => null}
            isForSingleDivision={isForSingleDivision}
            readOnly
            takerMarkets={takerMarkets}
          />
        </ModalContent>
      ) : (
        <ModalContent key={state.currentStep} className={modalContentClassName}>
          <FormProvider {...methods}>
            {state.display === 'form' && (
              <form>
                {/* step 1 - category select */}
                {state.currentStep === 'category' ? (
                  <CategorySelect
                    advanceStep={() => dispatch({ type: 'stepForward' })}
                    isForSingleDivision={isForSingleDivision}
                    takerMarkets={takerMarkets}
                    setEditMode={(isEditMode) => dispatch({ type: 'setEditMode', payload: { isEditMode } })}
                  />
                ) : (
                  // additional steps show a rule title and description
                  <div className="space-y-6">
                    <div className="text-sm">{description}</div>
                    {/* step 2 - division select */}
                    {state.currentStep === 'criteria' && (
                      <>
                        <CriteriaSelect errors={errors} setErrors={setErrors} toCurrency={toCurrency} />
                        {!isForSingleDivision && (
                          <>
                            <ExistingRulesAlert takerMarkets={takerMarkets} />
                            <DivisionSelectTable
                              hasError={errors.find((error) => error.type === 'takerMarkets')}
                              onSelect={onTakerMarketTableSelect}
                              selectedTakerMarkets={selectedTakerMarkets}
                              takerMarkets={takerMarkets}
                              type={type}
                            />
                          </>
                        )}
                      </>
                    )}
                    {/* step 3 - summary */}
                    {state.currentStep === 'summary' && (
                      <ReviewRule
                        exchangeRates={exchangeRates}
                        toCurrency={toCurrency}
                        showError={submitErrors.showError}
                        errorTakerMarkets={submitErrors.takerMarkets}
                      />
                    )}
                  </div>
                )}
              </form>
            )}
            {/* create another */}
            {state.display === 'createAnother' && (
              <CategorySelect
                advanceStep={(category) => {
                  dispatch({ type: 'createAnother', payload: { category } });
                }}
                display={state.display}
                isForSingleDivision={isForSingleDivision}
                setEditMode={(isEditMode) => dispatch({ type: 'setEditMode', payload: { isEditMode } })}
                takerMarkets={takerMarkets}
              />
            )}
          </FormProvider>
        </ModalContent>
      )}
      <ModalActions>
        <>
          {state.display === 'createAnother' || state.currentStep === 'category' ? (
            <Button onClick={handleClose} variant="secondary">
              {t('core.close')}
            </Button>
          ) : (
            <>
              {state.availableSteps.indexOf(state.currentStep) !== 0 && (
                <Button onClick={handleStepBack} variant="secondary">
                  {t('core.back')}
                </Button>
              )}
              {state.currentStep === 'criteria' && <Button onClick={handleStepForward}>{t('core.next')}</Button>}
              {state.currentStep === 'summary' && (
                <Button onClick={methods.handleSubmit(onSubmit)} loading={loading} variant="cta">
                  {t('recurringRules.applyRule')}
                </Button>
              )}
            </>
          )}
        </>
      </ModalActions>
    </>
  );
};

const RecurringRulesForm = (props: Omit<RecurringRulesFormProps, 'setDisableModalClose'>) => {
  const [disableModalClose, setDisableModalClose] = useState(false);

  return (
    <Modal
      className="min-w-[unset]"
      onClose={props.onClose}
      open={props.open}
      disableOutsideClickClose={disableModalClose}
    >
      <QueryBoundaries>
        <RecurringRulesFormComponent {...props} setDisableModalClose={setDisableModalClose} />
      </QueryBoundaries>
    </Modal>
  );
};

export default RecurringRulesForm;
