import { useTranslation } from 'react-i18next';
import ExcludedInvoicesAlert, { ExcludedInvoicesAlertProps } from '@/components/ExcludedInvoicesAlert';
import useTakerMarketsGroups, { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import RuleExcludedInvoicesAlert, {
  RuleExcludedInvoicesAlertProps,
} from '@/features/recurringRules/components/RuleExcludedInvoicesAlert';
import useEnableRecurringRules from '@/features/recurringRules/utils/useEnableRecurringRules';
import TabActionsDropdown from './TabActionsDropdown';

interface TabContentProps {
  description: string;
  showActions: boolean;
  showDescription: boolean;
  showExcludedAlert: boolean;
  showRuleExcludedAlert?: boolean;
  tab: TakerMarketGroupType;
  excludedAlertProps?: ExcludedInvoicesAlertProps;
  ruleExcludedAlertProps?: RuleExcludedInvoicesAlertProps;
}

const TabContent = ({
  description,
  excludedAlertProps,
  showActions,
  showDescription,
  showExcludedAlert,
  tab,
  showRuleExcludedAlert,
  ruleExcludedAlertProps,
}: TabContentProps) => (
  <div className="flex-1 space-y-4">
    <div className="flex min-h-[48px] items-center justify-between gap-6">
      {showDescription && <div>{description}</div>}
      {showActions && <TabActionsDropdown tab={tab} />}
    </div>
    {showExcludedAlert && excludedAlertProps && (
      <div>
        <ExcludedInvoicesAlert tab={tab} {...excludedAlertProps} />
      </div>
    )}
    {showRuleExcludedAlert && ruleExcludedAlertProps && (
      <div>
        <RuleExcludedInvoicesAlert tab={tab} {...ruleExcludedAlertProps} />
      </div>
    )}
  </div>
);

const TabActions = ({ activeTab }: { activeTab: TakerMarketGroupType }) => {
  const { t } = useTranslation();
  const takerMarketsGroups = useTakerMarketsGroups();
  const enableRecurringRules = useEnableRecurringRules();

  const showPreferredDescription = takerMarketsGroups['PREFERRED'].rollup.count > 0;
  const showNameYourRateActions = takerMarketsGroups['NAME_YOUR_RATE'].takerMarkets.length > 0;
  const showBenchmarkActions = takerMarketsGroups['BENCHMARK'].takerMarkets.length > 0;
  const showFixedActions = takerMarketsGroups['FIXED'].takerMarkets.length > 0;

  const tabConfig: Record<TakerMarketGroupType, TabContentProps> = {
    BENCHMARK: {
      description: t('takerMarketTabs.variableRateDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.BENCHMARK.rollup.excludedAmount,
        takerMarkets: enableRecurringRules
          ? takerMarketsGroups.BENCHMARK.takerMarketsWithoutRules
          : takerMarketsGroups.BENCHMARK.takerMarkets,
      },
      showActions: showBenchmarkActions,
      showDescription: true,
      showExcludedAlert: takerMarketsGroups.BENCHMARK.rollup.excludedAmount > 0,
      tab: 'BENCHMARK',
      showRuleExcludedAlert: enableRecurringRules,
      ruleExcludedAlertProps: {
        excludedAmount: takerMarketsGroups.BENCHMARK.rollup.rulesExcludedAmount,
        takerMarkets: takerMarketsGroups.BENCHMARK.takerMarketsWithRules,
      },
    },
    FIXED: {
      description: t('takerMarketTabs.fixedRateDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.FIXED.rollup.excludedAmount,
        takerMarkets: enableRecurringRules
          ? takerMarketsGroups.FIXED.takerMarketsWithoutRules
          : takerMarketsGroups.FIXED.takerMarkets,
      },
      showActions: showFixedActions,
      showDescription: true,
      showExcludedAlert: takerMarketsGroups.FIXED.rollup.excludedAmount > 0,
      tab: 'FIXED',
      showRuleExcludedAlert: enableRecurringRules,
      ruleExcludedAlertProps: {
        excludedAmount: takerMarketsGroups.FIXED.rollup.rulesExcludedAmount,
        takerMarkets: takerMarketsGroups.FIXED.takerMarketsWithRules,
      },
    },
    INACTIVE: {
      description: t('takerMarketTabs.inactiveMarketsDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.INACTIVE.rollup.excludedAmount,
        takerMarkets: enableRecurringRules
          ? takerMarketsGroups.INACTIVE.takerMarketsWithoutRules
          : takerMarketsGroups.INACTIVE.takerMarkets,
      },
      showActions: false,
      showDescription: true,
      showExcludedAlert: takerMarketsGroups.INACTIVE.rollup.excludedAmount > 0,
      tab: 'INACTIVE',
      showRuleExcludedAlert: enableRecurringRules,
      ruleExcludedAlertProps: {
        excludedAmount: takerMarketsGroups.INACTIVE.rollup.rulesExcludedAmount,
        takerMarkets: takerMarketsGroups.INACTIVE.takerMarketsWithRules,
      },
    },
    NAME_YOUR_RATE: {
      description: t('takerMarketTabs.nameYourRateDescription'),
      excludedAlertProps: {
        excludedAmount: takerMarketsGroups.NAME_YOUR_RATE.rollup.excludedAmount,
        takerMarkets: enableRecurringRules
          ? takerMarketsGroups.NAME_YOUR_RATE.takerMarketsWithoutRules
          : takerMarketsGroups.NAME_YOUR_RATE.takerMarkets,
      },
      showActions: showNameYourRateActions,
      showDescription: takerMarketsGroups.NAME_YOUR_RATE.rollup.count > 0,
      showExcludedAlert: takerMarketsGroups.NAME_YOUR_RATE.rollup.excludedAmount > 0,
      tab: 'NAME_YOUR_RATE',
      showRuleExcludedAlert: enableRecurringRules,
      ruleExcludedAlertProps: {
        excludedAmount: takerMarketsGroups.NAME_YOUR_RATE.rollup.rulesExcludedAmount,
        takerMarkets: takerMarketsGroups.NAME_YOUR_RATE.takerMarketsWithRules,
      },
    },
    PREFERRED: {
      description: t('takerMarketTabs.preferredRateDescription'),
      showActions: false,
      showDescription: showPreferredDescription,
      showExcludedAlert: false,
      tab: 'PREFERRED',
      showRuleExcludedAlert: enableRecurringRules,
      ruleExcludedAlertProps: {
        excludedAmount: takerMarketsGroups.PREFERRED.rollup.rulesExcludedAmount,
        takerMarkets: takerMarketsGroups.PREFERRED.takerMarketsWithRules,
      },
    },
  };

  return (
    <div className="flex min-h-[80px] justify-between p-4 sm:flex-row sm:items-center sm:space-y-0">
      <TabContent {...tabConfig[activeTab]} />
    </div>
  );
};

export default TabActions;
