import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo, useSnackbar } from '@c2fo/liquidity';
import { CircleCheckIcon, WalletIcon } from '@c2fo/liquidity/icons';
import useSubmitPreferredRenewalRequest from '@/data/useSubmitPreferredRenewalRequest';
import { TakerMarket } from '@/data/useTakerMarkets';
import useUserDetails from '@/data/useUserDetails';
import { useRefetchStatsQueries } from '@/lib/serverSentEvents/useServerSideEventListeners';
import { useReporting } from '@/reporting';
import { getTokenContent } from '@/utils/token';

const RenewalRequestModal = ({
  open,
  onClose,
  takerMarkets,
}: {
  open: boolean;
  onClose: () => void;
  takerMarkets: TakerMarket[];
}) => {
  const [hasRequestedRenewal, setHasRequestedRenewal] = useState(false);
  const { t } = useTranslation();
  const { track } = useReporting();
  const tokenContent = getTokenContent();
  const { data: userData } = useUserDetails();
  const { mutateAsync: submitPreferredRenewalRequest, isLoading: isLoadingSubmitPreferredRenewalRequest } =
    useSubmitPreferredRenewalRequest();
  const showSnackbar = useSnackbar();
  const { refetchManyTakerMarkets } = useRefetchStatsQueries();

  const title = hasRequestedRenewal
    ? t('preferredRenewalModal.confirmation.title')
    : t('preferredRenewalModal.request.title');

  const RenewalRequestContent = () => (
    <>
      <div className="flex justify-center">
        <WalletIcon className="h-20 w-auto" />
      </div>
      <div>{t('preferredRenewalModal.request.description')}</div>
      <div>{t('preferredRenewalModal.request.instruction')}</div>
    </>
  );

  const RenewalConfirmationContent = () => (
    <>
      <div className="flex justify-center">
        <CircleCheckIcon className="h-20 w-auto" />
      </div>
      <div>{t('preferredRenewalModal.confirmation.description')}</div>
    </>
  );

  const handleRenewalRequest = async () => {
    const preferredOfferRenewalRequest = {
      userUuid: tokenContent?.payload?.user?.uuid,
      accountId: userData?.me?.account?.id,
      srmEmail: userData?.me?.account?.srm?.emailAddress,
      divisionMarketUuids: takerMarkets.map((tm) => {
        return {
          divisionUuid: tm.takerDivisionUuid,
          marketUuid: tm.marketUuid,
        };
      }),
    };
    track('preferred-offer::renewal::requested', { preferredOfferRenewalRequest });

    try {
      await Promise.all(
        takerMarkets.map(async (tm) => {
          if (tm.offerConfig.uuid) {
            return submitPreferredRenewalRequest({ offerUuid: tm.offerConfig.uuid });
          }
        })
      );

      setHasRequestedRenewal(true);
    } catch (error) {
      showSnackbar({ message: t('core.error') });
    }
  };

  const onModalClose = () => {
    if (hasRequestedRenewal) {
      // Allows the UI to update and understand the user has already submitted a renewal request
      // Why here and not in the submit method above? This will ensure the user has time to read the text in the success variant of the modal
      refetchManyTakerMarkets(
        takerMarkets.map((tm) => {
          return {
            marketUuid: tm.marketUuid,
            takerId: tm.takerDivisionId,
          };
        })
      );
    }

    onClose();
  };

  return (
    <Modal onClose={onModalClose} open={open} size="sm">
      <ModalTitleInfo>{title}</ModalTitleInfo>
      <ModalContent center>
        {hasRequestedRenewal ? <RenewalConfirmationContent /> : <RenewalRequestContent />}
      </ModalContent>
      <ModalActions className="sm:justify-center">
        <Button
          variant={hasRequestedRenewal ? 'secondary' : 'cta'}
          onClick={hasRequestedRenewal ? onModalClose : handleRenewalRequest}
          className="sm:w-1/2"
          loading={isLoadingSubmitPreferredRenewalRequest}
          disabled={
            !hasRequestedRenewal && (!userData || !takerMarkets.length || isLoadingSubmitPreferredRenewalRequest)
          }
        >
          {hasRequestedRenewal ? t('core.close') : t('preferredRenewalModal.request.action')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default RenewalRequestModal;
