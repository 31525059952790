import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Switch, useSnackbar } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CheckIcon, TimesIcon } from '@c2fo/liquidity/icons';
import { useIsAgreementRequired } from '@/data/useAgreements';
import { TakerMarket } from '@/data/useTakerMarkets';
import useToggleOffer, { ToggleOfferParams } from '@/data/useToggleOffer';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';
import NameYourRateSetOffer from './SetOffer';

const NameYourRateParticipationToggle = ({
  disabled = false,
  takerMarket,
}: {
  disabled?: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions([takerMarket]);
  const { mutateAsync: toggleOffer, isLoading: toggleOfferIsLoading } = useToggleOffer();
  const isAgreementRequired = useIsAgreementRequired([takerMarket.marketUuid]);
  const [isReviewOfferModalOpen, setIsReviewOfferModalOpen] = useState(false);

  const isDisabledDueToClosedMarket = !takerMarket.offerConfig.isEnabled && takerMarket.marketIsEnabled === false;
  const toggleDisabled = disabled || !canEditOffers || toggleOfferIsLoading || isDisabledDueToClosedMarket;

  const onClick = async () => {
    // When a market has custom terms, the user must always go through the submit flow and accept them.
    if (isAgreementRequired && !takerMarket.offerConfig.isEnabled) {
      return setIsReviewOfferModalOpen(true);
    }

    const submitValues = {
      marketUuid: takerMarket.offerConfig.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      seaUuid: takerMarket.offerConfig.uuid ?? undefined,
      frequency: takerMarket.offerConfig.frequency,
    } satisfies ToggleOfferParams;

    return await toggleOffer(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('offerSubmitDialog.participationUpdated') });
        track('participation::submitted', {
          ...submitValues,
          offerConfig: {
            ...takerMarket.offerConfig,
            isEnabled: !takerMarket.offerConfig.isEnabled,
          },
        });
        setIsReviewOfferModalOpen(false);
      },
      onError: () => showSnackbar({ message: t('offerSubmitDialog.participationError') }),
    });
  };

  return (
    <>
      <NameYourRateSetOffer
        open={isReviewOfferModalOpen}
        onClose={() => setIsReviewOfferModalOpen(false)}
        takerMarket={takerMarket}
        showReviewStep
      />
      <Switch
        accessibilityLabel={t('core.toggleParticipation')}
        checked={takerMarket.offerConfig.isEnabled ?? false}
        disabled={toggleDisabled}
        onCheckedChange={onClick}
        size="large"
        uncheckedColor="error-500"
      >
        {(checked) =>
          checked ? (
            toggleOfferIsLoading ? (
              <Spinner className="fill-primary-500 " />
            ) : (
              <CheckIcon fill={colors.success[500]} className="h-full w-full p-1" />
            )
          ) : toggleOfferIsLoading ? (
            <Spinner className="fill-error-500" />
          ) : (
            <TimesIcon fill={colors.error[500]} className="h-full w-full p-1" />
          )
        }
      </Switch>
    </>
  );
};

export default NameYourRateParticipationToggle;
