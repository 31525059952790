import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Chip, cn, Logo } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { ArrowLeftIcon, CaretRightFilledIcon, LanguageIcon, MoneyBillIcon, TimesIcon } from '@c2fo/liquidity/icons';
import useRecentAwards from '@/data/useRecentAwards';
import { getLanguage } from '@/i18n';
import { useReporting } from '@/reporting';
import logout from '@/utils/logout';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import useSmbUser from '@/utils/useSmbUser';
import CurrencySelect from '../CurrencySelect';
import QueryBoundaries from '../QueryBoundaries';
import LanguageChangeMenu from './LanguageChangeMenu';
import { useLinks } from './Navigation';
import RecentAwardsDropdown from './RecentAwardsDropdown';
import UserMenu, { useUserName } from './UserMenu';

type MobileNavigationItems = 'recentAwards' | 'language' | 'userMenu' | 'currency' | null;

const RecentAwardsMobileNavigationItem = ({
  setItemSelect,
}: {
  setItemSelect: (item: MobileNavigationItems) => void;
}) => {
  const { t } = useTranslation();
  const isSmbUser = useSmbUser();
  const { data: awards } = useRecentAwards();

  return !isSmbUser && awards?.length ? (
    <div>
      <button
        className="flex h-16 w-full items-center justify-between border-b border-l-4 border-l-transparent px-4 transition-all duration-200 hover:bg-lightBlue-50"
        type="button"
        onClick={() => setItemSelect('recentAwards')}
      >
        <span>{t('taker.recentAwardsDropdown.title')}</span>
        <CaretRightFilledIcon className="h-5 w-5" aria-hidden="true" fill={colors.gray[600]} />
      </button>
    </div>
  ) : null;
};

export default function MobileNavigation({
  navIsOpen,
  setNavIsOpen,
}: {
  navIsOpen: boolean;
  setNavIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const current = pathname?.split('/')?.[2];
  const ref = useRef<HTMLDivElement>(null);
  const isLarge = useMedia('(min-width: 1024px)');
  const selectedCurrency = useSelectedCurrency();
  const [itemSelect, setItemSelect] = useState<MobileNavigationItems>(null);
  const language = useMemo(() => getLanguage(i18n.language), [i18n.language]);
  const name = useUserName();
  const links = useLinks();
  const isSmbUser = useSmbUser();
  const { track } = useReporting();

  const handleNavClose = useCallback(() => {
    setNavIsOpen(false);
    setItemSelect(null);
  }, [setNavIsOpen]);

  // prevent body scrolling when menu is open
  useEffect(() => {
    if (navIsOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  }, [navIsOpen]);

  // close menu if open and viewport transitions to desktop
  useEffect(() => {
    if (isLarge && navIsOpen) {
      document.documentElement.style.overflow = '';
      handleNavClose();
    }
  }, [handleNavClose, isLarge, navIsOpen]);

  // close when action is taken outside the menu
  useEffect(() => {
    const handleClickInside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        handleNavClose();
      }
    };

    document.addEventListener('mousedown', handleClickInside);
    document.addEventListener('touchstart', handleClickInside);

    return () => {
      document.removeEventListener('mousedown', handleClickInside);
      document.removeEventListener('touchstart', handleClickInside);
    };
  }, [ref, handleNavClose]);

  return (
    <>
      <div
        className={cn(
          'invisible fixed bottom-0 top-0 z-10 w-full bg-black opacity-0 transition-all duration-300 lg:hidden',
          {
            'visible opacity-25': navIsOpen,
          }
        )}
        ref={ref}
      />
      <div
        className={cn(
          'fixed -left-full bottom-0 top-0 z-10 flex h-full w-full max-w-sm flex-col bg-white shadow-md transition-all duration-200 lg:hidden',
          {
            'left-0': navIsOpen,
          }
        )}
      >
        <div className="flex h-16 min-h-[64px] items-center justify-between border-b px-4">
          {!isSmbUser ? (
            <Link to="/supplier/markets" aria-label={t('ariaLabel.linkToHomepage')} onClick={handleNavClose}>
              <Logo className="h-6 w-auto" color="primary" brandMark />
            </Link>
          ) : (
            <Logo className="h-6 w-auto" color="primary" brandMark />
          )}
          <button onClick={handleNavClose} type="button">
            <TimesIcon fill={colors.secondary[500]} className="h-6 w-6" />
          </button>
        </div>
        {itemSelect ? (
          <div className="relative overflow-scroll">
            <div
              className={cn('fixed z-10 h-16 w-full max-w-sm gap-2 border-b bg-white px-4 py-3 font-bold', {
                'h-24': itemSelect === 'currency',
                'flex items-center gap-2': itemSelect !== 'currency',
              })}
            >
              <div className="flex items-center gap-2">
                <button type="button" onClick={() => setItemSelect(null)}>
                  <ArrowLeftIcon />
                </button>
                <div>
                  <span>
                    {itemSelect === 'recentAwards' && t('taker.recentAwardsDropdown.title')}
                    {itemSelect === 'language' && t('profile.subscription.languagePreference')}
                    {itemSelect === 'currency' && t('core.preferredCurrency')}
                    {itemSelect === 'userMenu' && name}
                  </span>
                </div>
              </div>
              {itemSelect === 'currency' && (
                <div className="ml-6 mt-1 text-sm font-normal text-text-secondary">
                  {t('core.preferredCurrencyMessage')}
                </div>
              )}
            </div>
            <div className={cn('mt-16', { 'mt-24': itemSelect === 'currency' })}>
              {itemSelect === 'recentAwards' && <RecentAwardsDropdown itemsOnly onClose={handleNavClose} />}
              {itemSelect === 'language' && <LanguageChangeMenu itemsOnly onClose={handleNavClose} />}
              {itemSelect === 'currency' && <CurrencySelect itemsOnly onClose={handleNavClose} />}
              {itemSelect === 'userMenu' && <UserMenu itemsOnly onClose={handleNavClose} />}
            </div>
          </div>
        ) : (
          <>
            <div>
              {links.map((link) => (
                <div key={link.key} className="border-b">
                  <Link
                    to={link.to}
                    aria-current={current === link.key ? 'page' : undefined}
                    className={cn(
                      'flex h-16 items-center border-l-4 border-l-transparent px-4 transition-all duration-200 hover:bg-lightBlue-50',
                      {
                        'border-l-lightBlue-500 bg-lightBlue-50': current === link.key,
                      }
                    )}
                    onClick={handleNavClose}
                  >
                    {t(`${link.text}`)}
                    {link.isNew && (
                      <Chip className="ml-1.5" isRound label={t('core.beta')} size="xs" type="info" variant="filled" />
                    )}
                  </Link>
                </div>
              ))}
              <QueryBoundaries>
                <RecentAwardsMobileNavigationItem setItemSelect={setItemSelect} />
              </QueryBoundaries>
            </div>
            <div className="mt-auto">
              <div className="flex h-16 items-center">
                <button
                  className="flex h-16 w-full items-center justify-between border-y border-l-4 border-l-transparent px-4 transition-all duration-200 hover:bg-lightBlue-50"
                  type="button"
                  onClick={() => setItemSelect('language')}
                >
                  <span className="flex items-center gap-2">
                    <LanguageIcon className="h-9 w-auto" fill={colors.secondary[500]} /> {language}
                  </span>
                  <CaretRightFilledIcon className="h-5 w-5" aria-hidden="true" fill={colors.gray[600]} />
                </button>
              </div>
              <div className="flex h-16 items-center">
                <button
                  className="flex h-16 w-full items-center justify-between border-b border-l-4 border-l-transparent px-4 transition-all duration-200 hover:bg-lightBlue-50"
                  type="button"
                  onClick={() => setItemSelect('currency')}
                >
                  <span className="flex items-center gap-2">
                    <MoneyBillIcon className="h-8 w-auto" fill={colors.secondary[500]} /> {selectedCurrency}
                  </span>
                  <CaretRightFilledIcon className="h-5 w-5" aria-hidden="true" fill={colors.gray[600]} />
                </button>
              </div>
              <div className="flex h-16 items-center">
                <button
                  className="flex h-16 w-full items-center justify-between border-b border-l-4 border-l-transparent px-4 transition-all duration-200 hover:bg-lightBlue-50"
                  type="button"
                  onClick={() => setItemSelect('userMenu')}
                >
                  <span className="flex items-center gap-2">{name}</span>
                  <CaretRightFilledIcon className="h-5 w-5" aria-hidden="true" fill={colors.gray[600]} />
                </button>
              </div>
              <div className="flex h-16 items-center">
                <button
                  type="button"
                  onClick={() => {
                    track('user-menu::logout::clicked');
                    logout();
                  }}
                  className="flex h-16 w-full items-center justify-between border-l-4 border-l-transparent px-4 transition-all duration-200 hover:bg-lightBlue-50"
                >
                  {t('core.logout')}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
