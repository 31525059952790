import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';

const useSubmitInformationRequestPreferred = () => {
  const [enableSeaApi] = useFeature('enterprise-ui_enableSeaWrites');
  const [enableSeaInformationRequests] = useFeature('enterprise-ui_enableSeaInformationRequests');

  return useMutation({
    mutationKey: ['submitInformationRequestPreferred'],
    mutationFn: async () => {
      // If SEA is ready, make the API call that will ultimately create a Salesforce Case
      if (enableSeaApi && enableSeaInformationRequests) {
        return apiClient
          .post(
            'api/supplier-experience/information-request/preferred-term',
            undefined satisfies paths['/information-request/preferred-term']['post']['requestBody']
          )
          .json<paths['/information-request/preferred-term']['post']['responses']['204']['content']>();
      }
    },
  });
};

export default useSubmitInformationRequestPreferred;
