import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import download from '@/assets/download.svg?url';
import downloadError from '@/assets/downloaderror.svg?url';
import getToken from '@/utils/token';

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;

const FileDownloadModal = () => {
  const token = getToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);

  // consolidated invoice download URL: usercast + '/api/c2fo/taker/invoices/eligible/csv?jobUuid'
  // consolidated invoice download params
  const downloadConsolidatedInvoice = searchParams.get('downloadConsolidatedInvoice');

  // consolidated history downloadURL: usercast + '/api/c2fo/taker/consolidated-history/award/archive?startDate&endDate&makerIds'
  // consolidated history download params
  const downloadConsolidatedTakerAward = searchParams.get('downloadConsolidatedTakerAward');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const makerIds = searchParams.getAll('makerIds');

  const currentDate = new Date();
  const expiration = new Date(searchParams.get('urlExpirationDate') ?? currentDate);
  const isUrlExpired = expiration < currentDate;

  useEffect(() => {
    setShowModal(!!downloadConsolidatedInvoice || !!downloadConsolidatedTakerAward);
  }, [downloadConsolidatedInvoice, downloadConsolidatedTakerAward]);

  let downloadUrl;
  let onFinish;

  if (downloadConsolidatedInvoice) {
    const params = qs.stringify({
      jobUuid: downloadConsolidatedInvoice,
      token,
    });

    downloadUrl = `${prefixUrl}/usercast/api/c2fo/taker/invoices/eligible/csv?${params}`;
    onFinish = () => {
      setShowModal(false);
      navigate('/supplier/markets');
    };
  } else if (downloadConsolidatedTakerAward) {
    const params = qs.stringify(
      {
        startDate,
        endDate,
        makerIds,
        token,
      },
      { indices: false }
    );

    downloadUrl = `${prefixUrl}/usercast/api/c2fo/taker/consolidated-history/award/archive?${params}`;
    onFinish = () => {
      // clean up the params to remove token and non-URL pretty characters
      const cancelParams = qs.stringify(
        {
          startDate,
          endDate,
          makerIds,
        },
        { indices: false }
      );

      setShowModal(false);
      navigate(`/supplier/history?${cancelParams}`);
    };
  }

  return downloadUrl && !isUrlExpired ? (
    <Modal open={showModal} onClose={onFinish} size="sm">
      <ModalTitleInfo>{t('core.authenticatedDownload.title')}</ModalTitleInfo>
      <ModalContent center>
        <img src={download} alt="download" className="h-16 w-16" />
        <p className="text-center text-base">{t('core.authenticatedDownload.description')}</p>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={onFinish}>
          {t('core.cancel')}
        </Button>
        <a className="shrink" href={downloadUrl} onClick={onFinish} target="_self">
          <Button variant="primary">{t('core.download')}</Button>
        </a>
      </ModalActions>
    </Modal>
  ) : isUrlExpired ? (
    <Modal onClose={onFinish} open={showModal} className="sm:w-[550px]">
      <ModalTitleInfo>{t('core.authenticatedDownload.expiredTitle')}</ModalTitleInfo>
      <ModalContent className="flex flex-col items-center gap-6 text-center">
        <img src={downloadError} alt="download error" className="h-16 w-16" />
        <div>{t('core.authenticatedDownload.expired')}</div>
      </ModalContent>
      <ModalActions className="sm:justify-center">
        <Button variant="primary" onClick={onFinish} className="sm:w-1/2">
          {t('core.close')}
        </Button>
      </ModalActions>
    </Modal>
  ) : null;
};

export default FileDownloadModal;
