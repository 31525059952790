import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { cn, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CaretDownFilledIcon, MoneyBillIcon } from '@c2fo/liquidity/icons';
import QueryBoundaries from '@/components/QueryBoundaries';
import useCurrencies from '@/data/useCurrencies';
import useUpdateUserCurrency from '@/data/useUpdateUserCurrency';
import { useReporting } from '@/reporting';
import useSelectedCurrency from '@/utils/useSelectedCurrency';

const CurrencySelectComponent = ({ itemsOnly, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const currencies = useCurrencies();
  const queryClient = useQueryClient();
  const selectedCurrency = useSelectedCurrency();
  const { mutate } = useUpdateUserCurrency();

  const handleChange = (currency: string) => {
    queryClient.setQueryData(['selected-currency'], currency);
    // do not want this to block updating currency
    // if it fails, we fallback to default behavior for currency
    mutate({ aggregateCurrencyFormat: currency });
    track('currency::changed', { currency });
  };

  const items = currencies.map((currency) => ({
    label: currency,
    name: currency,
    onClick: () => handleChange(currency),
  }));

  return currencies.length > 1 ? (
    <>
      {itemsOnly ? (
        items?.map((item) => (
          <div key={item.name} className="relative flex w-full border-b last:border-b-0">
            <button
              className={cn(
                'flex flex-grow justify-between gap-2 border-l-4 border-l-transparent px-4 py-3 font-normal text-text-primary transition-all duration-200 hover:bg-lightBlue-50',
                { 'border-l-lightBlue-500 bg-lightBlue-50': selectedCurrency === item.label }
              )}
              onClick={() => {
                item.onClick();
                onClose?.();
              }}
              type="button"
            >
              {item.label}
            </button>
          </div>
        ))
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger aria-label={t('core.preferredCurrency')}>
            <div className="flex h-9 items-center space-x-1">
              <MoneyBillIcon className="hidden h-7 w-auto lg:block" fill={colors.secondary[500]} />
              <CaretDownFilledIcon
                className="hidden h-5 w-5 sm:block"
                fill={colors.secondary[500]}
                aria-hidden="true"
              />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <div className="w-52">
              <div className="mb-2 border-b px-4 pb-4 pt-2">
                <h6 className="m-0 font-medium">{t('core.preferredCurrency')}</h6>
                <p className="mt-2 text-sm text-text-secondary">{t('core.preferredCurrencyMessage')}</p>
              </div>
            </div>
            {items.map((item) => (
              <DropdownMenuItem key={item.name} onClick={item.onClick} selected={selectedCurrency === item.label}>
                {item.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  ) : null;
};

const CurrencySelect = ({ itemsOnly = false, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => (
  <QueryBoundaries>
    <CurrencySelectComponent itemsOnly={itemsOnly} onClose={onClose} />
  </QueryBoundaries>
);

export default CurrencySelect;
