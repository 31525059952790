import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInverse, useSnackbar } from '@c2fo/liquidity';
import useDeleteRecurringRule from '@/data/useDeleteRecurringRule';
import useRecurringRules from '@/data/useRecurringRules';
import { useReporting } from '@/reporting';
import batchPromiseAllSettled from '@/utils/batchPromiseAllSettled';
import useLocaleFormat from '@/utils/useLocaleFormat';

interface DeleteAllRulesModalProps {
  onClose: () => void;
  open: boolean;
}

const DeleteAllRulesModal = ({ onClose, open }: DeleteAllRulesModalProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asNumber } = useLocaleFormat();
  const recurringRules = useRecurringRules();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [contactSRM, setContactSRM] = useState(false);
  const { mutateAsync: deleteRecurringRule } = useDeleteRecurringRule();

  const handleClose = () => {
    setContactSRM(false);
    onClose();
  };

  const deleteAllRules = async () => {
    setLoading(true);

    const [results] = await Promise.allSettled([
      await batchPromiseAllSettled({
        fn: async (argument) => {
          await deleteRecurringRule(argument);
        },
        items: recurringRules.map(({ rule }) => ({
          id: rule.id,
          makerOrganizationUuid: rule.makerOrganizationUuid,
          marketId: rule.marketId,
          takerId: rule.takerId,
        })),
        size: 5,
      }),
    ]);

    if (results.status === 'rejected' || (results.status === 'fulfilled' && results.value.errors.length > 0)) {
      setLoading(false);
      setShowError(true);
    } else {
      handleClose();
      showSnackbar({ message: t('recurringRules.deleteAllRulesConfirm') });
    }
  };

  return (
    <Modal disableOutsideClickClose={loading} onClose={onClose} open={open} size="sm">
      <ModalTitleInverse>{t('recurringRules.deleteAllRules')}</ModalTitleInverse>
      <ModalContent className="space-y-4">
        {showError && (
          <>
            {contactSRM ? (
              <Alert
                description={t('core.contactSrm.description')}
                full
                title={t('core.contactSrm.title')}
                type="info"
              />
            ) : (
              <Alert
                action={{
                  onClick: () => {
                    // fire track event for slack channel
                    track('recurring-rules::error', { type: 'delete', ruleId: 'allRules' });
                    setContactSRM(true);
                  },
                  text: t('core.contactSrm'),
                }}
                description={t('core.reachOutForAssistance')}
                full
                title={t('recurringRules.deleteRulesError')}
                type="error"
              />
            )}
          </>
        )}
        <div>{t('recurringRules.deleteRulesWarning')}</div>
        <div>{t('recurringRules.deleteAllConfirmation')}</div>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={loading} onClick={deleteAllRules} variant="destructive">
          {`${t('recurringRules.deleteAllRules')} (${asNumber({ value: recurringRules.length })})`}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteAllRulesModal;
