import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { useToken } from '@/utils/token';

const DownloadInvoicesModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { tokenContent } = useToken();
  const email = tokenContent?.payload.user.emailAddress;

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <ModalTitleInfo>{t('taker.downloadInvoicesTitle')}</ModalTitleInfo>
      <ModalContent center>
        <p>{t('taker.downloadInvoicesSubtitle', { email })}</p>
      </ModalContent>
      <ModalActions>
        <Button variant="primary" onClick={onClose}>
          {t('core.ok')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DownloadInvoicesModal;
