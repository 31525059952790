import { ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { TypingIcon } from '@c2fo/liquidity/icons';
import { EnterpriseOfferType } from '@/generated/gql/graphql';
import successSvg from '@/assets/success.svg?url';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useDraftOffer from '@/data/useDraftOffer';
import useSubmitPreferredOffer from '@/data/useSubmitPreferredOffer';
import { Agreement } from '@/features/agreements/Agreement';
import { useReporting } from '@/reporting';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { DraftOfferProps } from '../common/DraftOffer.types';
import { DraftOfferTemplate } from '../common/DraftOfferTemplate';
import { useTakerMarketsInDraftOffer } from '../common/useTakerMarketsInDraftOffer';

export const DraftOfferPreferred = ({ draftOfferUuid }: DraftOfferProps) => {
  const { data: draftOfferData } = useDraftOffer(draftOfferUuid);
  const { takerMarkets: takerMarketsInOffer, stats } = useTakerMarketsInDraftOffer(draftOfferUuid);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFinalApprovalModalOpen, setIsFinalApprovalModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { track } = useReporting();
  const { asDateString } = useLocaleFormat();
  const { mutateAsync: submitPreferredDraftOffer, isLoading: isSubmittingDraftOffer } = useSubmitPreferredOffer();
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired(takerMarketsInOffer);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const draftOfferTrackEventData: {
    draftOfferUuid: string;
    type: EnterpriseOfferType;
  } = {
    draftOfferUuid,
    type: 'PREFERRED',
  };

  useEffectOnce(() => {
    track('draft-offer::viewed', draftOfferTrackEventData);
  });

  const formattedTermEndDate = useMemo(() => {
    if (!draftOfferData?.termExpirationDate) {
      return t('core.na');
    }

    const date = asDateString(trimDateTimestamp(draftOfferData.termExpirationDate), {
      month: 'long',
      year: 'numeric',
    });

    return date;
  }, [asDateString, draftOfferData?.termExpirationDate, t]);

  const displayRowContent: { title: ReactNode; content: ReactNode }[] = [
    {
      title: t('draftOffer.labels.offerType'),
      content: t('core.offerType.preferred'),
    },
    {
      title: t('draftOffer.labels.customer'),
      content: takerMarketsInOffer.length ? (
        <span className="flex flex-col gap-3">
          {takerMarketsInOffer.map((tm) => (
            <span key={tm.id}>
              <span className="block text-right">{tm.makerDivisionName}</span>
              <span className="block text-right text-xs font-normal">{getTakerMarketDivisionTitle(tm)?.title}</span>
            </span>
          ))}
        </span>
      ) : (
        t('core.na')
      ),
    },
    {
      title: t('draftOffer.labels.rate'),
      content: <span className="uppercase">{stats.formattedRate}</span>,
    },
    {
      title: t('draftOffer.labels.term'),
      content: formattedTermEndDate,
    },
  ];

  const onCancel = () => {
    track('draft-offer::cancel', draftOfferTrackEventData);
    navigate('/supplier/markets');
  };

  const onApprove = () => {
    setIsFinalApprovalModalOpen(true);
  };

  const onFinalApproval = async () => {
    try {
      setIsSubmitError(false);

      track('draft-offer::submit', draftOfferTrackEventData);

      if (!draftOfferUuid || !draftOfferData) {
        throw new Error('Not enough draft offer data available to submit the offer.');
      }

      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      await submitPreferredDraftOffer({
        draftId: draftOfferUuid,
        marketUuid: draftOfferData.supplierMarkets.map((sm) => sm.marketUuid)[0],
        supplierDivisionUuids: draftOfferData.supplierMarkets?.map((sm) => sm.supplierDivisionUuid),
        // We're using this to preemptively update the react query cache for takers markets
        setOfferConfigQueryData: {
          isEnabled: true,
          offerType: 'PREFERRED_TERM',
          maxApr: draftOfferData.rateType === 'APR' ? draftOfferData.rate : null,
          maxDiscount: draftOfferData.rateType === 'DISC' ? draftOfferData.rate : null,
          isDiscountBidding: draftOfferData.rateType === 'DISC' ? true : false,
          disableAfterMarketClearsDate: draftOfferData.termExpirationDate,
        },
      });

      setIsSuccessModalOpen(true);
      setIsFinalApprovalModalOpen(false);

      track('draft-offer::submit::success', draftOfferTrackEventData);
    } catch (error) {
      console.error(error);
      setIsSubmitError(true);
      track('draft-offer::submit::error', {
        ...draftOfferTrackEventData,
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };

  const onViewDetails = () => {
    track('draft-offer::view-details', draftOfferTrackEventData);
    navigate('/supplier/markets/preferred');
  };

  return (
    <div>
      <DraftOfferTemplate
        cardSlot={
          <div className="space-y-4">
            <div className="divide-y divide-gray-300 rounded-lg bg-gray-100">
              {displayRowContent.map((rowData, i) => (
                <div key={i} className="flex items-center justify-between gap-4 p-4">
                  <p className="text-sm">{rowData.title}</p>
                  <p className="text-sm font-semibold">{rowData.content}</p>
                </div>
              ))}
            </div>
            <Agreement
              takersMarkets={takerMarketsInOffer}
              onChange={(v) => {
                setIsAgreementsChecked(v);
                setIsAgreementsErrorNotChecked(false);
              }}
            />
          </div>
        }
        actionSlot={
          <>
            <Button className="w-1/3" onClick={onCancel} variant="secondary">
              {t('core.cancel')}
            </Button>
            <Button className="w-1/3" onClick={onApprove} variant="cta">
              {t('draftOffer.approve')}
            </Button>
          </>
        }
      />

      {/* Final Confirmation Modal */}
      <Modal open={isFinalApprovalModalOpen} onClose={() => setIsFinalApprovalModalOpen(false)} size="sm">
        <ModalTitleInfo>{t('draftOffer.finalApproval')}</ModalTitleInfo>
        <ModalContent center>
          <TypingIcon className="h-24 w-auto lg:block" />
          <p className="max-w-xs text-center text-base">{t('draftOffer.confirmation.body.preferred')}</p>
          {isSubmitError && <Alert type="error" variant="filled" description={t('draftOffer.error')} full />}
          {isAgreementsErrorNotChecked && (
            <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
          )}
        </ModalContent>
        <ModalActions>
          <Button className="shrink" full variant="secondary" onClick={() => setIsFinalApprovalModalOpen(false)}>
            {t('core.cancel')}
          </Button>
          <Button
            className="shrink"
            full
            variant="cta"
            onClick={onFinalApproval}
            loading={isSubmittingDraftOffer}
            disabled={isSubmittingDraftOffer}
          >
            {t('draftOffer.approve')}
          </Button>
        </ModalActions>
      </Modal>

      {/* Success Modal */}
      <Modal open={isSuccessModalOpen} size="sm">
        <ModalTitleInfo>{t('draftOffer.success.title.preferred')}</ModalTitleInfo>
        <ModalContent center>
          <img src={successSvg} alt="success" />
          <p className="max-w-xs text-center text-base">{t('draftOffer.success.body.preferred')}</p>
        </ModalContent>
        <ModalActions className="sm:justify-center">
          <Button variant="cta" onClick={onViewDetails} className="sm:w-1/2">
            {t('draftOffer.viewDetails')}
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
