import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@c2fo/liquidity';
import { CustomEligibleInvoiceDpe } from '@/data/useEligibleInvoices';

export interface DpeRangePickerProps {
  onChange: (range: CustomEligibleInvoiceDpe | undefined) => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: ReactNode;
}

interface DpeRange {
  from?: string;
  to?: string;
}

const DpeRangePicker = ({ onChange, open, onOpenChange, trigger }: DpeRangePickerProps) => {
  const { t } = useTranslation();
  const [inputRange, setInputRange] = useState<DpeRange>();
  const [isValid, setIsValid] = useState(false);

  // Reset inputRange when the popover opens
  useEffect(() => {
    if (open) {
      setInputRange({ from: '', to: '' });
      setIsValid(false);
    }
  }, [open]);

  const handleInputChange = (field: 'from' | 'to') => (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow empty string for deleting values from fields
    if (inputValue === '') {
      const updatedRange = { ...inputRange, [field]: '' };
      setInputRange(updatedRange);
      setIsValid(false); // Set is valid to false while there are empty strings
      return;
    }

    // Check if the value is a valid whole number and greater than or equal to 0
    const numberValue = parseFloat(inputValue);
    if (Number.isInteger(numberValue) && numberValue >= 0) {
      const updatedRange = { ...inputRange, [field]: numberValue.toString() };
      setInputRange(updatedRange);

      // Validate the range (to must be >= from)
      const isValidRange =
        updatedRange.from !== undefined &&
        updatedRange.to !== undefined &&
        Number(updatedRange.to) >= Number(updatedRange.from);

      setIsValid(isValidRange);
    } else {
      setIsValid(false);
    }
  };

  const applyRange = () => {
    onChange({
      startDpe: inputRange?.from ?? '0',
      endDpe: inputRange?.to ?? '0',
    });
    onOpenChange?.(false);
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger>{trigger}</Popover.Trigger>
      <Popover.Content align="start" sideOffset={5} variant="light">
        <div className="flex flex-col gap-2">
          {(['from', 'to'] as const).map((field) => (
            <div key={field} className="flex flex-col">
              <label className="pb-1 text-sm capitalize">{t(`core.${field}`)}</label>
              <input
                name={field}
                className="h-10 rounded-md border px-2 focus-visible:outline-primary-500"
                type="text"
                value={inputRange?.[field] ?? ''}
                onChange={handleInputChange(field)}
              />
            </div>
          ))}
          <div className="mt-3 flex justify-center gap-2">
            <Button variant="secondary" size="sm" onClick={() => onOpenChange?.(false)}>
              {t('core.cancel')}
            </Button>
            <Button variant="primary" size="sm" disabled={!isValid} onClick={applyRange}>
              {t('core.apply')}
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};

export default DpeRangePicker;
