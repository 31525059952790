import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInverse, useSnackbar } from '@c2fo/liquidity';
import useDeleteRecurringRule from '@/data/useDeleteRecurringRule';
import useDeleteRecurringRuleCriteria from '@/data/useDeleteRecurringRuleCriteria';
import { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';
import { useReporting } from '@/reporting';

interface DeleteAllRulesModalProps {
  onClose: () => void;
  open: boolean;
  ruleMeta: {
    rule: RecurringRule;
    category: RecurringRuleCategory;
  };
}

const DeleteRuleCriteriaModal = ({ open, onClose, ruleMeta }: DeleteAllRulesModalProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const [showError, setShowError] = useState(false);
  const [contactSRM, setContactSRM] = useState(false);
  const { mutateAsync: deleteRecurringRule, isLoading: deleteRecurringRuleLoading } = useDeleteRecurringRule();
  const { mutateAsync: deleteRecurringRuleCriteria, isLoading: deleteRecurringRuleCriteriaLoading } =
    useDeleteRecurringRuleCriteria();

  const handleClose = () => {
    setContactSRM(false);
    onClose();
  };

  const deleteRule = async () => {
    if (ruleMeta.rule.count === 1) {
      await deleteRecurringRule(
        {
          id: ruleMeta.rule.id,
          makerOrganizationUuid: ruleMeta.rule.makerOrganizationUuid,
          marketId: ruleMeta.rule.marketId,
          takerId: ruleMeta.rule.takerId,
        },
        {
          onSuccess: () => {
            handleClose();
            showSnackbar({ message: t('recurringRules.deleteRuleSuccess') });
          },
          onError: () => {
            setShowError(true);
          },
        }
      );
    } else {
      await deleteRecurringRuleCriteria(
        {
          id: ruleMeta.rule.id,
          criteriaType: ruleMeta.category,
          makerOrganizationUuid: ruleMeta.rule.makerOrganizationUuid,
          marketId: ruleMeta.rule.marketId,
          marketUuid: ruleMeta.rule.marketUuid,
          takerId: ruleMeta.rule.takerId,
          takerUuid: ruleMeta.rule.takerUuid,
        },
        {
          onSuccess: () => {
            handleClose();
          },
          onError: () => {
            setShowError(true);
          },
        }
      );
    }
  };

  return (
    <Modal
      disableOutsideClickClose={deleteRecurringRuleLoading || deleteRecurringRuleCriteriaLoading}
      onClose={handleClose}
      open={open}
      size="sm"
    >
      <ModalTitleInverse>{t('recurringRules.deleteRule')}</ModalTitleInverse>
      <ModalContent className="space-y-4">
        {showError && (
          <>
            {contactSRM ? (
              <Alert
                description={t('core.contactSrm.description')}
                full
                title={t('core.contactSrm.title')}
                type="info"
              />
            ) : (
              <Alert
                action={{
                  onClick: () => {
                    // fire track event for slack channel
                    track('recurring-rules::error', { type: 'delete', ruleId: ruleMeta.rule.id });
                    setContactSRM(true);
                  },
                  text: t('core.contactSrm'),
                }}
                description={t('core.reachOutForAssistance')}
                full
                title={t('recurringRules.error.deleteRule')}
                type="error"
              />
            )}
          </>
        )}
        <div>{t('recurringRules.deleteRuleWarning')}</div>
        <div>{t('recurringRules.deleteConfirmation')}</div>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button
          loading={deleteRecurringRuleLoading || deleteRecurringRuleCriteriaLoading}
          onClick={deleteRule}
          variant="destructive"
        >
          {t('recurringRules.deleteRule')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteRuleCriteriaModal;
