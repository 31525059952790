import { RecurringRuleCategory } from '@/data/useRecurringRules';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import { RecurringRuleFormValues } from '../RecurringRulesForm';

const defaultRuleValues: Record<RecurringRuleCategory, RecurringRuleFormValues> = {
  amount: {
    category: 'amount',
    criteria: [{ type: 'gteInvoiceAmount', value: '' }],
    takerMarkets: [],
  },
  dpe: {
    category: 'dpe',
    criteria: [{ type: 'gteDpe', value: '' }],
    takerMarkets: [],
  },
  dueDate: {
    category: 'dueDate',
    criteria: [{ type: 'toDueDate', value: dateAsIsoString() }],
    takerMarkets: [],
  },
  invoiceId: {
    category: 'invoiceId',
    criteria: [{ type: 'excludedVoucherIds', value: '' }],
    takerMarkets: [],
  },
};

export default defaultRuleValues;
