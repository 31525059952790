import { ReactNode, useEffect, useRef, useState } from 'react';
import { cn, Popover } from '@c2fo/liquidity';

interface EllipsisTooltipProps {
  content: ReactNode;
  trigger: ReactNode;
}

const EllipsisTooltip = ({ content, trigger }: EllipsisTooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const compareSize = () => {
    const compare = ref.current && ref.current.offsetWidth < ref.current.scrollWidth;
    setIsOverflow(!!compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  });

  const handleMouseEnter = () => {
    if (isOverflow) {
      setOpen(true);
    }
  };

  return (
    <Popover open={open}>
      <Popover.Trigger
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div
          ref={ref}
          className={cn('max-w-fit truncate', { 'cursor-text': !isOverflow })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setOpen(false)}
        >
          {trigger}
        </div>
      </Popover.Trigger>
      <Popover.Content arrow className="z-20">
        {content}
      </Popover.Content>
    </Popover>
  );
};

export default EllipsisTooltip;
