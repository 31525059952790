import { useTranslation } from 'react-i18next';
import { cn } from '@c2fo/liquidity';
import Divider from '@/components/Divider';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import { TakerMarket } from '@/data/useTakerMarkets';
import useTakerMarketStats from '@/data/useTakerMarketStats';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useLocaleFormat from '@/utils/useLocaleFormat';
import TakerMarketBanner from '../takerMarketBanner/TakerMarketBanner';

const TakerMarketStatsGridComponent = ({
  marketId,
  takerId,
  takerMarket,
}: {
  marketId: string;
  takerId: number;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { asCurrency, asNumber, asPercent } = useLocaleFormat();
  const { data: takerMarketStats } = useTakerMarketStats({ marketId, takerId });
  const { listenToMarketStats } = useServerSideEventListeners();
  const listeningForMarketStat = listenToMarketStats([
    {
      marketUuid: takerMarket.marketUuid,
      takerId,
    },
  ]);

  if (!takerMarketStats) {
    return <div>{t('fetchError.takerMarketStats')}</div>;
  }

  const showDiscount = takerMarket.marketPricingType !== 'BENCHMARK';
  const showApr = !takerMarketStats.takerConfigIsDiscountBidding;
  const showDivider = showDiscount && showApr;

  return (
    <div className="rounded-md bg-white shadow-md">
      <TakerMarketBanner loading={listeningForMarketStat} takerMarket={takerMarket} />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        <div className="border-b p-8 text-center md:border-r lg:border-b-0">
          <div className="text-sm capitalize">{t('taker.marketSummaryAvailableAR')}</div>
          <div className={cn('text-2xl font-semibold', { 'blur-sm': listeningForMarketStat })}>
            {asCurrency(takerMarketStats.eligibleInvoiceAmount, takerMarketStats.currency)}
          </div>
          <div className="text-sm uppercase">
            {t('taker.marketSummaryAverageDPE', {
              dpe: asNumber({ value: takerMarketStats.eligibleDpeWeightedAvg, decimals: 0 }),
            })}
          </div>
        </div>
        <div className="relative flex items-center justify-center border-b p-8 lg:border-b-0 lg:border-r">
          <div className="text-center">
            <div className="text-sm capitalize">{t('taker.marketSummaryClearing')}</div>
            <div
              className={cn('text-2xl font-semibold', {
                'text-primary-500': takerMarketStats.marketIsEnabled || false,
                'blur-sm': listeningForMarketStat,
              })}
            >
              {takerMarketStats.marketIsEnabled
                ? asCurrency(takerMarketStats.acceptedInvoiceAmount, takerMarketStats.currency)
                : t('core.na')}
            </div>
            <div
              className={cn('text-sm uppercase', {
                invisible: !takerMarketStats.marketIsEnabled || false,
              })}
            >
              {t('taker.marketSummaryAverageDPE', { dpe: takerMarketStats.acceptedDpeWeightedAvg })}
            </div>
          </div>
        </div>
        <div className="border-b p-8 text-center md:border-b-0 md:border-r">
          <div className="text-sm capitalize">{t('taker.marketSummaryNonClearing')}</div>
          <div
            className={cn('text-2xl font-semibold', {
              'text-red-500': takerMarketStats.marketIsEnabled || false,
              'blur-sm': listeningForMarketStat,
            })}
          >
            {takerMarketStats.marketIsEnabled
              ? asCurrency(takerMarketStats.notAcceptedInvoiceAmount, takerMarketStats.currency)
              : t('core.na')}
          </div>
          <div
            className={cn('text-sm uppercase', {
              invisible: !takerMarketStats.marketIsEnabled || false,
            })}
          >
            {t('taker.marketSummaryAverageDPE', { dpe: takerMarketStats.notAcceptedDpeWeightedAvg || 0 })}
          </div>
        </div>
        <div className="p-8 text-center">
          <div className="text-sm capitalize">{t('taker.marketSummaryDiscount')}</div>
          <div className={cn('text-2xl font-semibold', { 'blur-sm': listeningForMarketStat })}>
            {takerMarketStats.marketIsEnabled
              ? asCurrency(takerMarketStats.acceptedEarn || 0, takerMarketStats.currency)
              : t('core.na')}
          </div>
          <div
            className={cn('flex items-center justify-center space-x-2 text-sm uppercase', {
              invisible: !takerMarketStats.marketIsEnabled || false,
            })}
          >
            {showDiscount && (
              <div>
                {`${asPercent({ value: takerMarketStats.acceptedDiscountWeightedAvg / 100 || 0, decimals: 2 })} ${t(
                  'core.discountAbbreviation'
                )}`}
              </div>
            )}
            {showDivider && <Divider vertical />}
            {showApr && (
              <div>
                {`${asPercent({ value: takerMarketStats.acceptedAprWeightedAvg / 100 || 0, decimals: 2 })} ${t(
                  'core.aprAbbreviation'
                )}`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TakerMarketStatsGrid = ({
  marketId,
  takerId,
  takerMarket,
}: {
  marketId: string;
  takerId: number;
  takerMarket: TakerMarket;
}) => (
  <QueryBoundaries LoadingComponent={() => <Skeleton height="h-64" />}>
    <TakerMarketStatsGridComponent marketId={marketId} takerId={takerId} takerMarket={takerMarket} />
  </QueryBoundaries>
);

export default TakerMarketStatsGrid;
