import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useRefetchStatsQueries,
  useServerSideEventListeners,
} from '@/lib/serverSentEvents/useServerSideEventListeners';

export const useRefetchAndSubscribeForRules = () => {
  const queryClient = useQueryClient();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  const refetchAndSubscribeRules = useCallback(
    ({
      makerOrganizationUuid,
      takerMarkets,
    }: {
      makerOrganizationUuid: string;
      takerMarkets: {
        marketUuid: string;
        takerId: number;
      }[];
    }) => {
      queryClient.refetchQueries({ queryKey: ['recurring-rules', makerOrganizationUuid] });

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        refetchOneTakerMarket({ marketUuid, takerId });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats', marketUuid, takerId] });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats-filtered', marketUuid, takerId] });
      });

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        subscribeToMarketStats({
          marketUuid,
          takerId,
        });
      });
    },
    [queryClient, refetchOneTakerMarket, subscribeToMarketStats]
  );

  return { refetchAndSubscribeRules };
};

export default useRefetchAndSubscribeForRules;
