import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { FormatPreferencesInput } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import { useToken } from '@/utils/token';

const UPDATE_USER_CURRENCY = graphql(`
  mutation UpdateUserCurrency($users: [UserInput!]) {
    users(users: $users) {
      success
    }
  }
`);

const useUpdateUserCurrency = () => {
  const queryClient = useQueryClient();
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';

  return useMutation({
    mutationKey: ['updateUserCurrency'],
    mutationFn: ({ aggregateCurrencyFormat }: FormatPreferencesInput) => {
      const input = {
        users: [
          {
            id: authServiceUuid,
            formatPreferences: {
              aggregateCurrencyFormat,
            },
          },
        ],
      };

      return gqlClient.request(UPDATE_USER_CURRENCY, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user-details']);
    },
  });
};

export default useUpdateUserCurrency;
