import { useEffect } from 'react';
import { createEventSource } from 'eventsource-client';
import { useToken } from '@/utils/token';
import { useServerSideEventListeners } from './useServerSideEventListeners';

interface ServerSentEvent {
  done: boolean;
  event: string;
  marketUuid: string;
  takerIds: number[];
}

const prefixUrl = import.meta.env.VITE_GATEWAY_URL;
const url = `${prefixUrl}/api/c2fo/push-events`;

export const useServerSideEvents = () => {
  const { token } = useToken();
  const { notifyMarketStatsSubscribers } = useServerSideEventListeners();

  useEffect(() => {
    let eventSource: ReturnType<typeof createEventSource> | null = null;

    const connectToServerSideEvents = () => {
      try {
        eventSource = createEventSource({
          url,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onMessage: ({ data }) => {
            try {
              const parsedData: ServerSentEvent = JSON.parse(data);

              if (parsedData.done && parsedData.event === 'market.stats') {
                notifyMarketStatsSubscribers({
                  marketUuid: parsedData.marketUuid,
                  takerIds: parsedData.takerIds,
                });
              }
            } catch {
              // message data could not be parsed
            }
          },
        });
      } catch {
        // error opening connection
      }
    };

    connectToServerSideEvents();

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};
