import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useUserDetails from '@/data/useUserDetails';
import { SUPPORTED_LOCALES } from '@/i18n';
import QueryBoundaries from './QueryBoundaries';

const isSupportedLanguage = (code: string) => SUPPORTED_LOCALES.find((locale) => locale.code === code)?.code;

const DefaultTranslationInitializerComponent = () => {
  const { i18n } = useTranslation();
  const { data: locale } = useUserDetails((data) => data.me?.personalInfo?.locale);

  useLayoutEffect(() => {
    if (locale && !!isSupportedLanguage(locale)) {
      // change if current language is not the same as the user's preferred language
      if (i18n.language !== locale) {
        i18n.changeLanguage(locale);
      }
    }
  }, [i18n, locale]);

  return null;
};

const DefaultTranslationInitializer = () => (
  <QueryBoundaries>
    <DefaultTranslationInitializerComponent />
  </QueryBoundaries>
);

export default DefaultTranslationInitializer;
