import { Suspense, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  Button,
  ModalActions,
  ModalContent,
  ModalFullScreen,
  ModalTitleInverse,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Spinner,
} from '@c2fo/liquidity';
import { Agreement } from '@/generated/gql/graphql';
import { AgreementWithContent, agreementsByCmsId, useAgreementContent } from '@/data/useAgreements';

interface AdditionalAgreementsModalProps {
  agreements: Agreement[];
  open: boolean;
  onClose: () => void;
}

const AdditionalAgreementsModalContent = ({ agreements, open }: AdditionalAgreementsModalProps) => {
  // Organizes agreements by CMS ID since multiple markets can share the same agreement
  const filteredAgreements = agreementsByCmsId(agreements);

  // Fetches the agreement content for each agreement's CMS ID
  const agreementContents = useAgreementContent(
    filteredAgreements.map((agreement) => agreement.cmsId),
    open
  );

  // Combine the agreement metadata with the agreement content
  const agreementsWithContent: AgreementWithContent[] = agreementContents.map(({ data: agreementContent }) => {
    const agreement = filteredAgreements.find((agreement) => agreement.cmsId === agreementContent?.cmsId);

    return {
      cmsId: agreement?.cmsId ?? '',
      marketId: agreement?.marketIds[0] ?? '',
      id: agreement?.agreementIds[0] ?? '',
      title: agreementContent?.title ?? '',
      copy: agreementContent?.copy ?? '',
      updatedDate: agreementContent?.updatedDate ?? '',
      updatedLabel: agreementContent?.updatedLabel ?? '',
    };
  });
  const [displayedAgreementIndex, setDisplayedAgreementIndex] = useState<number>(0);
  const displayedAgreement: AgreementWithContent | undefined = agreementsWithContent[displayedAgreementIndex];

  const showMultipleAgreements = agreementsWithContent.length > 1;

  return (
    <>
      {showMultipleAgreements && displayedAgreement && (
        <div className="m-auto pb-4 sm:w-3/4">
          <div className="w-full sm:w-3/4 md:w-3/5">
            <Select
              onValueChange={(value) =>
                setDisplayedAgreementIndex(
                  agreementsWithContent.findIndex((agreement) => agreement.cmsId === value) ?? 0
                )
              }
              value={displayedAgreement.cmsId}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {agreementsWithContent.map((agreement, index) => {
                  return (
                    <SelectItem key={agreement.id[0]} value={agreement.cmsId}>
                      {`${agreement.title} (${t('core.countOfTotal', {
                        count: index + 1,
                        total: agreementsWithContent.length,
                      })})`}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
      )}
      <div className="m-auto  sm:w-3/4" dangerouslySetInnerHTML={{ __html: displayedAgreement?.copy ?? '' }} />
    </>
  );
};

const AdditionalAgreementsModal = (props: AdditionalAgreementsModalProps) => {
  useEffect(() => {
    const qualtricsButton = document.getElementById('QSIFeedbackButton-btn');

    if (qualtricsButton) {
      qualtricsButton.style.visibility = props.open ? 'hidden' : 'visible';
    }
  }, [props.open]);

  return (
    <ModalFullScreen open={props.open} onClose={props.onClose}>
      <ModalTitleInverse>{t('agreements.title')}</ModalTitleInverse>
      <ModalContent>
        <Suspense
          fallback={
            <div className="flex h-full w-full items-center justify-center">
              <Spinner className="h-12 w-12 fill-primary-500" />
            </div>
          }
        >
          <AdditionalAgreementsModalContent {...props} />
        </Suspense>
      </ModalContent>
      <ModalActions className="sm:justify-center">
        <Button onClick={props.onClose} variant="primary" className="sm:min-w-96">
          {t('agreements.close')}
        </Button>
      </ModalActions>
    </ModalFullScreen>
  );
};

export default AdditionalAgreementsModal;
