import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@c2fo/liquidity';
import { Pricing } from '@/generated/gql/graphql';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import useFeature, { Features } from '@/lib/features';
import { useRefetchStatsQueries } from '@/lib/serverSentEvents/useServerSideEventListeners';
import { fetchPlatformOffers } from './usePlatformOffers';
import { TakerMarket } from './useTakerMarkets';
import { TakerMarketGroupType } from './useTakerMarketsGroups';

const patchOfferExpirationDate = async ({
  makerOrganizationUuid,
  identifier,
  offerExpiration,
  offerType = 'NAME_YOUR_RATE',
}: {
  makerOrganizationUuid: string;
  identifier: string;
  offerExpiration: string | null;
  // Add support for Static when available
  offerType?: TakerMarketGroupType;
}) => {
  // Add support for Static when available
  const offerTypeSegment = offerType === 'BENCHMARK' ? 'benchmark-offers' : 'price-discovery-offers';

  return await apiClient.patch(
    `api/platform/maker-organizations/${makerOrganizationUuid}/${offerTypeSegment}/${identifier}`,
    {
      headers: {
        'c2fo-application-name': 'enterprise-ui',
      },
      json: {
        frequency: 'recurring',
        offerExpiration,
      },
    }
  );
};

const patchMultipleExpirationDatesSEA = async ({
  takerMarkets,
  expirationDate,
  pricingType,
}: {
  takerMarkets: TakerMarket[];
  expirationDate: string | null;
  pricingType: Pricing;
}) => {
  if (takerMarkets.length === 0) {
    return;
  }

  const seaOffers = takerMarkets
    .filter((tm) => tm.offerConfig.uuid)
    .map((tm) => ({ uuid: tm.offerConfig.uuid!, makerOrganizationUuid: tm.makerOrganizationUuid }));

  if (pricingType === 'BENCHMARK') {
    return await apiClient
      .patch(`api/supplier-experience/offers/benchmark/expirations`, {
        json: {
          offers: seaOffers,
          expirationDate,
          groupingKey: 'SUPPLIER_MARKET',
        } satisfies paths['/offers/benchmark/expirations']['patch']['requestBody']['content']['application/json'],
      })
      .json<paths['/offers/benchmark/expirations']['patch']['responses']['204']['content']>();
  }

  if (pricingType === 'STATIC') {
    return await apiClient
      .patch(`api/supplier-experience/offers/static/expirations`, {
        json: {
          offers: seaOffers,
          expirationDate,
          groupingKey: 'SUPPLIER_MARKET',
        } satisfies paths['/offers/static/expirations']['patch']['requestBody']['content']['application/json'],
      })
      .json<paths['/offers/static/expirations']['patch']['responses']['204']['content']>();
  }

  if (pricingType === 'PRICE_DISCOVERY') {
    return await apiClient
      .patch(`api/supplier-experience/offers/price-discovery/expirations`, {
        json: {
          offers: seaOffers,
          expirationDate,
          groupingKey: 'SUPPLIER_MARKET',
        } satisfies paths['/offers/price-discovery/expirations']['patch']['requestBody']['content']['application/json'],
      })
      .json<paths['/offers/price-discovery/expirations']['patch']['responses']['204']['content']>();
  }
};

export const useBulkExpirationDateUpdate = () => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const { refetchManyTakerMarkets } = useRefetchStatsQueries();
  const [enableSEA] = useFeature(Features['enterprise-ui_enableSeaWrites']);

  const applyBulkExpirationDateOperation = async ({
    takerMarkets,
    expirationDate,
  }: {
    takerMarkets: TakerMarket[];
    expirationDate: string | null;
  }) => {
    const successes: { marketUuid: string; divisionUuid: string }[] = [];

    showSnackbar({ message: t('expirationDate.bulkUpdate.snackbar.updating') });

    if (enableSEA) {
      // Split into price discovery, static, and benchmark groups with offers
      const staticMarkets = takerMarkets.filter((tm) => tm.marketPricingType === 'STATIC' && tm.offerConfig.uuid);
      const benchmarkMarkets = takerMarkets.filter((tm) => tm.marketPricingType === 'BENCHMARK' && tm.offerConfig.uuid);
      const priceDiscoveryMarkets = takerMarkets.filter(
        (tm) => tm.marketPricingType === 'PRICE_DISCOVERY' && tm.offerConfig.uuid
      );

      const staticFunc = async () => {
        try {
          await patchMultipleExpirationDatesSEA({
            takerMarkets: staticMarkets,
            expirationDate,
            pricingType: 'STATIC',
          });
          successes.push(
            ...staticMarkets.map((market) => ({
              marketUuid: market.marketUuid,
              divisionUuid: market.takerDivisionUuid,
            }))
          );
        } catch {}
      };

      const benchmarkFunc = async () => {
        try {
          await patchMultipleExpirationDatesSEA({
            takerMarkets: benchmarkMarkets,
            expirationDate,
            pricingType: 'BENCHMARK',
          });
          successes.push(
            ...benchmarkMarkets.map((market) => ({
              marketUuid: market.marketUuid,
              divisionUuid: market.takerDivisionUuid,
            }))
          );
        } catch {}
      };

      const pdFunc = async () => {
        try {
          await patchMultipleExpirationDatesSEA({
            takerMarkets: priceDiscoveryMarkets,
            expirationDate,
            pricingType: 'PRICE_DISCOVERY',
          });
          successes.push(
            ...priceDiscoveryMarkets.map((market) => ({
              marketUuid: market.marketUuid,
              divisionUuid: market.takerDivisionUuid,
            }))
          );
        } catch {}
      };

      await Promise.allSettled([staticFunc(), benchmarkFunc(), pdFunc()]);
    } else {
      const platformOffers = await fetchPlatformOffers({ takerMarkets });

      // Promise.allSettled() is used to keep track of which promises resolve/reject
      await Promise.allSettled(
        platformOffers.map(async (platformOffer) => {
          try {
            // Offer must have an identifier to be updated
            if (platformOffer.identifier && platformOffer.status === 'active') {
              await patchOfferExpirationDate({
                makerOrganizationUuid: platformOffer.makerOrganizationUuid,
                identifier: platformOffer.identifier,
                offerExpiration: expirationDate,
              });
              successes.push({ marketUuid: platformOffer.marketUuid, divisionUuid: platformOffer.divisionUuid });
            }
          } catch {
            // We're not explicitly catching errors here because we will ultimately compare all takerMarkets with successes.
            // This will not only account for api errors, but also for takerMarkets that never matched with platformOffers to begin with.
          }
        })
      );
    }

    refetchManyTakerMarkets(
      takerMarkets.map((tm) => {
        return {
          marketUuid: tm.marketUuid,
          takerId: tm.takerDivisionId,
        };
      })
    );

    // Find any taker markets that were not specifically updated
    // (This could mean the taker market never matched a platform offer, or the platform api update failed)
    const takerMarketsNotUpdated = takerMarkets.filter(
      (takerMarket) =>
        !successes.some(
          (success) =>
            success.marketUuid === takerMarket.marketUuid && success.divisionUuid === takerMarket.takerDivisionUuid
        )
    );

    if (takerMarketsNotUpdated.length > 0) {
      showSnackbar({ message: t('expirationDate.bulkUpdate.snackbar.failure') });
    } else {
      showSnackbar({ message: t('expirationDate.bulkUpdate.snackbar.success') });
    }

    return {
      takerMarketsNotUpdated,
    };
  };

  return {
    applyBulkExpirationDateOperation,
  };
};
