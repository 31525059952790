import { RecurringRuleFormValues } from '../RecurringRulesForm';

type Steps = 'category' | 'criteria' | 'summary';

export interface RecurringRulesFormState {
  availableSteps: Steps[];
  currentStep: Steps;
  display: 'createAnother' | 'form';
  isEditMode: boolean;
}

export type RecurringRulesFormAction =
  | {
      type: 'setDisplay';
      payload: { display: RecurringRulesFormState['display'] };
    }
  | {
      type: 'stepBack';
    }
  | {
      type: 'stepForward';
    }
  | {
      type: 'createAnother';
      payload: { category: RecurringRuleFormValues['category'] };
    }
  | {
      type: 'setEditMode';
      payload: { isEditMode: RecurringRulesFormState['isEditMode'] };
    };

export const getInitialState = ({
  category,
  createAnother = false,
  mode,
}: {
  category?: RecurringRuleFormValues['category'];
  createAnother?: boolean;
  mode?: 'edit' | 'create' | 'readOnly';
}): RecurringRulesFormState => {
  // if createAnother is true, skip the category step and go straight to criteria
  // keep category as an available step to allow moving back to category step
  if (category && createAnother) {
    return {
      availableSteps: ['category', 'criteria', 'summary'],
      currentStep: 'criteria',
      display: 'form',
      isEditMode: mode === 'edit',
    };
  }

  // if category is provided, we skip the category step and go straight to division
  if (category) {
    return {
      availableSteps: ['criteria', 'summary'],
      currentStep: 'criteria',
      display: 'form',
      isEditMode: mode === 'edit',
    };
  }

  return {
    availableSteps: ['category', 'criteria', 'summary'],
    currentStep: 'category',
    display: 'form',
    isEditMode: mode === 'edit',
  };
};

export const recurringRulesFormReducer = (state: RecurringRulesFormState, action: RecurringRulesFormAction) => {
  const { type } = action;

  switch (type) {
    case 'setDisplay': {
      return {
        ...state,
        display: action.payload.display,
      };
    }

    case 'stepBack': {
      const currentStep = state.currentStep;
      const currentStepIndex = state.availableSteps.indexOf(currentStep);
      const prevStep: RecurringRulesFormState['currentStep'] =
        currentStepIndex === 0 ? currentStep : state.availableSteps[currentStepIndex - 1];

      return {
        ...state,
        currentStep: prevStep,
      };
    }

    case 'stepForward': {
      const currentStep = state.currentStep;
      const currentStepIndex = state.availableSteps.indexOf(currentStep);
      const nextStep: RecurringRulesFormState['currentStep'] =
        currentStepIndex === state.availableSteps.length - 1 ? currentStep : state.availableSteps[currentStepIndex + 1];

      return {
        ...state,
        currentStep: nextStep,
      };
    }

    case 'createAnother': {
      const updatedState = getInitialState({
        category: action.payload.category,
        createAnother: true,
      });

      return {
        ...state,
        ...updatedState,
      };
    }

    case 'setEditMode': {
      return {
        ...state,
        isEditMode: action.payload.isEditMode,
      };
    }

    default:
      return state;
  }
};
