import useFeature from '@/lib/features';

/*
 * check if recurring rules is enabled
 */

const useEnableRecurringRules = () => {
  const [enableRecurringRules] = useFeature('enterprise-ui_enableRecurringRules');
  return enableRecurringRules;
};

export default useEnableRecurringRules;
