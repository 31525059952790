import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';

const useSubmitPreferredRenewalRequest = () => {
  const [enableSeaApi] = useFeature('enterprise-ui_enableSeaWrites');
  const [enableSeaInformationRequests] = useFeature('enterprise-ui_enableSeaInformationRequests');

  return useMutation({
    mutationKey: ['submitPreferredRenewalRequest'],
    mutationFn: async ({ offerUuid }: { offerUuid: string }) => {
      // If SEA is ready, make the API call that will ultimately create a Salesforce Case
      if (enableSeaApi && enableSeaInformationRequests) {
        return apiClient
          .post(
            `api/supplier-experience/offers/preferred-term/${offerUuid}/request-renewal`,
            undefined satisfies paths['/offers/preferred-term/{uuid}/request-renewal']['post']['requestBody']
          )
          .json<paths['/offers/preferred-term/{uuid}/request-renewal']['post']['responses']['204']['content']>();
      }
    },
  });
};

export default useSubmitPreferredRenewalRequest;
