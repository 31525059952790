import { useQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { GetMeQuery } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import getToken from '@/utils/token';

const GET_ME = graphql(`
  query GetMe {
    me {
      account {
        newExperienceEnabled
        id
        srm {
          calendarLink
          emailAddress
          fallback
          firstName
          isCalendlyEnabled
          lastName
          phoneNumber
          team
        }
      }
      created
      divisions {
        accountId
        srm {
          team
        }
      }
      emailAddress
      formatPreferences {
        aggregateCurrencyFormat
      }
      intercomUserId
      loginHistory(limit: 2) {
        created
      }
      personalInfo {
        firstName
        lastName
        locale
        primaryPhoneNumber
      }
      supplierReadOnly
    }
  }
`);

function fetchUserDetails(): Promise<GetMeQuery> {
  return gqlClient.request(GET_ME);
}

/**
 * Fetches the user details for the currently logged-in user.
 */
export default function useUserDetails<TData = GetMeQuery>(select?: (data: GetMeQuery) => TData) {
  const token = getToken();

  return useQuery({
    enabled: !!token,
    queryKey: ['user-details'],
    queryFn: fetchUserDetails,
    select,
  });
}
