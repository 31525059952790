import { RecurringRule, RecurringRuleCategory } from '@/data/useRecurringRules';

const getRecurringRuleForCategory = (recurringRule: RecurringRule, category: RecurringRuleCategory) => {
  const includedRecurringRuleKeys = [
    category === 'invoiceId' ? 'excludedVoucherIds' : category,
    'id',
    'count',
    'currency',
    'makerOrganizationUuid',
    'marketId',
    'takerId',
  ];

  if (!recurringRule) {
    return;
  }

  return Object.fromEntries(
    Object.entries(recurringRule ?? {}).filter(([key]) => includedRecurringRuleKeys.includes(key))
  ) as RecurringRule;
};

export default getRecurringRuleForCategory;
