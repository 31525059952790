import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import download from '@/assets/download.svg?url';

export interface CreditNoteModalProps {
  handleDownload: () => void;
  onClose: () => void;
  open: boolean;
}

export default function CreditNoteModal({ handleDownload, onClose, open }: CreditNoteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <ModalTitleInfo>{t('core.authenticatedDownload.title')}</ModalTitleInfo>
      <ModalContent center>
        <img src={download} alt="download" className="h-16 w-16" />
        <p className="text-center text-base">{t('core.authenticatedDownload.description')}</p>
      </ModalContent>
      <ModalActions>
        <Button variant="secondary" onClick={onClose}>
          {t('core.cancel')}
        </Button>
        <Button onClick={handleDownload} variant="primary">
          {t('core.download')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
