import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInverse, useSnackbar } from '@c2fo/liquidity';
import useDeleteRecurringRule, { DeleteRecurringRuleArgument } from '@/data/useDeleteRecurringRule';
import { useReporting } from '@/reporting';

interface DeleteRuleModalProps {
  onClose: () => void;
  open: boolean;
  rule: DeleteRecurringRuleArgument;
}

const DeleteRuleModal = ({ open, onClose, rule }: DeleteRuleModalProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const [showError, setShowError] = useState(false);
  const [contactSRM, setContactSRM] = useState(false);
  const { mutateAsync: deleteRecurringRule, isLoading } = useDeleteRecurringRule();

  const handleClose = () => {
    setContactSRM(false);
    onClose();
  };

  const deleteRule = async () => {
    await deleteRecurringRule(
      {
        id: rule.id,
        makerOrganizationUuid: rule.makerOrganizationUuid,
        marketId: rule.marketId,
        takerId: rule.takerId,
      },
      {
        onSuccess: () => {
          handleClose();
          showSnackbar({ message: t('recurringRules.deleteAllRulesSuccess') });
        },
        onError: () => {
          setShowError(true);
        },
      }
    );
  };

  return (
    <Modal disableOutsideClickClose={isLoading} onClose={handleClose} open={open} size="sm">
      <ModalTitleInverse>{t('recurringRules.deleteRules')}</ModalTitleInverse>
      <ModalContent>
        {showError && (
          <>
            {contactSRM ? (
              <Alert
                description={t('core.contactSrm.description')}
                full
                title={t('core.contactSrm.title')}
                type="info"
              />
            ) : (
              <Alert
                action={{
                  onClick: () => {
                    // fire track event for slack channel
                    track('recurring-rules::error', { type: 'delete', ruleId: rule.id });
                    setContactSRM(true);
                  },
                  text: t('core.contactSrm'),
                }}
                description={t('core.reachOutForAssistance')}
                full
                title={t('recurringRules.error.deleteRules')}
                type="error"
              />
            )}
          </>
        )}
        <span className="flex flex-col gap-3">
          <span>{t('recurringRules.deleteAllRulesWarning')}</span>
          <span>{t('recurringRules.deleteAllConfirmation')}</span>
        </span>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={isLoading} onClick={deleteRule} variant="destructive">
          {t('recurringRules.deleteRules')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteRuleModal;
