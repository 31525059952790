import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalTitleInfo } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CheckIcon } from '@c2fo/liquidity/icons';

export interface ModalProps {
  open?: boolean;
  onClose?: () => void;
}

export default function HowItWorksModal({ open, onClose }: ModalProps) {
  const { t } = useTranslation();
  const steps = [
    {
      title: t('taker.seeHowItWorks.paidSameWayDialog.steps.customersUploadInvoices.title'),
      description: t('taker.seeHowItWorks.paidSameWayDialog.steps.customersUploadInvoices.description'),
    },
    {
      title: t('taker.seeHowItWorks.paidSameWayDialog.steps.chooseInvoices.title'),
      description: t('taker.seeHowItWorks.paidSameWayDialog.steps.chooseInvoices.description'),
    },
    {
      title: t('taker.seeHowItWorks.paidSameWayDialog.steps.nameRate.title'),
      description: t('taker.seeHowItWorks.paidSameWayDialog.steps.nameRate.description'),
    },
    {
      title: t('taker.seeHowItWorks.paidSameWayDialog.steps.offersConsideredDaily.title'),
      description: t('taker.seeHowItWorks.paidSameWayDialog.steps.offersConsideredDaily.description'),
    },
    {
      title: t('taker.seeHowItWorks.paidSameWayDialog.steps.offerAcceptedPaidSameWayAsNow.title'),
      description: t('taker.seeHowItWorks.paidSameWayDialog.steps.offerAcceptedPaidSameWayAsNow.description'),
    },
  ] as const;
  const checks = [
    t('taker.seeHowItWorks.paidSameWayDialog.sidebarRules.paymentFromCustomer'),
    t('taker.seeHowItWorks.paidSameWayDialog.sidebarRules.nameYourRate'),
    t('taker.seeHowItWorks.paidSameWayDialog.sidebarRules.sameTerms'),
  ] as const;
  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitleInfo>{t('taker.seeHowItWorks.paidSameWayDialog.title')}</ModalTitleInfo>
      <div className="flex h-full basis-2/3 gap-4">
        <div className="h-full grow p-4">
          <div className="mt-8 flex flex-col gap-6">
            {steps.map((step, index) => (
              <div key={index} className="flex items-center gap-6">
                <div className="text-5xl font-bold text-secondary-500">{index + 1}</div>
                <div className="flex flex-col">
                  <div className="text-2xl font-medium text-secondary-500">{step.title}</div>
                  <div>{step.description}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="m-8">
            <Button variant="primary" full onClick={() => onClose?.()}>
              {t('taker.seeHowItWorks.paidSameWayDialog.backToOpportunities')}
            </Button>
          </div>
        </div>
        <div className="hidden basis-1/3 flex-col items-center justify-center bg-secondary-500 px-4 text-white sm:flex">
          <div>
            <h4>{t('taker.seeHowItWorks.paidSameWayDialog.sidebarTitle')}</h4>
            <div className="flex h-full flex-col gap-3">
              {checks.map((check, index) => (
                <div className="flex items-center gap-4" key={index}>
                  <div>
                    <CheckIcon fill={colors.primary[500]} />
                  </div>
                  <div className="text-lg italic">{check}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
